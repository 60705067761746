import { AspectRatio, Image, Avatar } from '@chakra-ui/react';

import avatarAlice from 'assets/images/avatar/Alice.jpg';
import avatarBecca from 'assets/images/avatar/Becca.jpg';
import avatarBecky from 'assets/images/avatar/Becky.jpg';
import avatarEmeline from 'assets/images/avatar/Emeline.jpg';
import avatarGenie from 'assets/images/avatar/Genie.jpg';
import avatarKat from 'assets/images/avatar/Kat.jpg';
import avatarLynn from 'assets/images/avatar/Lynn.jpg';
import avatarMark from 'assets/images/avatar/Mark.jpg';
import avatarMavis from 'assets/images/avatar/Mavis.jpg';
import avatarMax from 'assets/images/avatar/Max.jpg';
import avatarNita from 'assets/images/avatar/Nita.jpg';
import avatarRiven from 'assets/images/avatar/Riven.jpg';
import avatarSoking from 'assets/images/avatar/Soking.jpg';
import avatarWendy from 'assets/images/avatar/Wendy.jpg';

import salonAlice from 'assets/images/salon/Alice.png';
import salonBecca from 'assets/images/salon/Becca.png';
import salonBecky from 'assets/images/salon/Becky.png';
import salonEmeline from 'assets/images/salon/Emeline.png';
import salonGenie from 'assets/images/salon/Genie.png';
import salonKat from 'assets/images/salon/Kat.png';
import salonLynn from 'assets/images/salon/Lynn.png';
import salonMark from 'assets/images/salon/Mark.png';
import salonMavis from 'assets/images/salon/Mavis.png';
import salonMax from 'assets/images/salon/Max.png';
import salonNita from 'assets/images/salon/Nita.png';
import salonRiven from 'assets/images/salon/Riven.png';
import salonSoking from 'assets/images/salon/Soking.png';
import salonWendy from 'assets/images/salon/Wendy.png';

import domeAlice from 'assets/images/dome/Alice.png';
import domeBecca from 'assets/images/dome/Becca.png';
import domeBecky from 'assets/images/dome/Becky.png';
import domeEmeline from 'assets/images/dome/Emeline.png';
import domeGenie from 'assets/images/dome/Genie.png';
import domeKat from 'assets/images/dome/Kat.png';
import domeLynn from 'assets/images/dome/Lynn.png';
import domeMark from 'assets/images/dome/Mark.png';
import domeMavis from 'assets/images/dome/Mavis.png';
import domeMax from 'assets/images/dome/Max.png';
import domeNita from 'assets/images/dome/Nita.png';
import domeRiven from 'assets/images/dome/Riven.png';
import domeSoking from 'assets/images/dome/Soking.png';
import domeWendy from 'assets/images/dome/Wendy.png';

import { DomeIcon } from 'components/icons';

const MENTOR_AVATAR = {
  Alice: {
    avatar: avatarAlice,
    salon: salonAlice,
    dome: domeAlice,
    name: 'Alice',
    lg: avatarAlice,
  },
  Becca: {
    avatar: avatarBecca,
    salon: salonBecca,
    dome: domeBecca,
    name: 'Becca',
    lg: avatarBecca,
  },
  Becky: {
    salon: salonBecky,
    avatar: avatarBecky,
    dome: domeBecky,
    name: 'Becky',
    lg: avatarBecky,
  },
  Emeline: {
    salon: salonEmeline,
    avatar: avatarEmeline,
    dome: domeEmeline,
    name: 'Emeline',
    lg: avatarEmeline,
  },
  Genie: {
    salon: salonGenie,
    avatar: avatarGenie,
    dome: domeGenie,
    name: 'Genie',
    lg: avatarGenie,
  },
  Kat: {
    salon: salonKat,
    avatar: avatarKat,
    dome: domeKat,
    name: 'Kat',
    lg: avatarKat,
  },
  Lynn: {
    salon: salonLynn,
    avatar: avatarLynn,
    dome: domeLynn,
    name: 'Lynn',
    lg: avatarLynn,
  },
  Mark: {
    salon: salonMark,
    avatar: avatarMark,
    dome: domeMark,
    name: 'Mark',
    lg: avatarMark,
  },
  Mavis: {
    salon: salonMavis,
    avatar: avatarMavis,
    dome: domeMavis,
    name: 'Mavis',
    lg: avatarMavis,
  },
  Max: {
    salon: salonMax,
    avatar: avatarMax,
    dome: domeMax,
    name: 'Max',
    lg: avatarMax,
  },
  Nita: {
    salon: salonNita,
    avatar: avatarNita,
    dome: domeNita,
    name: 'Nita',
    lg: avatarNita,
  },
  Riven: {
    salon: salonRiven,
    avatar: avatarRiven,
    dome: domeRiven,
    name: 'Riven',
    lg: avatarRiven,
  },
  Soking: {
    salon: salonSoking,
    avatar: avatarSoking,
    dome: domeSoking,
    name: 'Soking',
    lg: avatarSoking,
  },
  Wendy: {
    salon: salonWendy,
    avatar: avatarWendy,
    dome: domeWendy,
    name: 'Wendy',
    lg: avatarWendy,
  },
};

const MentorImage = props => {
  const { avatar, salon, dome } = props;
  if (avatar) {
    return (
      <Avatar
        w="60px"
        h="60px"
        border="2px solid #EA593D"
        name={avatar}
        src={MENTOR_AVATAR[avatar]?.avatar}
      />
    );
  }
  if (salon) {
    return (
      <AspectRatio w="100%" ratio={279 / 341}>
        <Image alt={salon} src={MENTOR_AVATAR[salon]?.salon} />
      </AspectRatio>
    );
  }
  if (dome) {
    return (
      <AspectRatio w="100%" ratio={450 / 550}>
        <Image
          alt={dome}
          src={MENTOR_AVATAR[dome]?.dome}
          fallback={<DomeIcon />}
        />
      </AspectRatio>
    );
  }
};

export default MentorImage;
