import axios from 'axios';
import { useForm } from 'react-hook-form';
import {
  Flex,
  Heading,
  Input,
  Button,
  FormErrorMessage,
  FormControl,
  FormLabel,
  useToast,
  VStack,
  Text,
} from '@chakra-ui/react';

const Form = () => {
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
  } = useForm();

  async function onSubmit(values) {
    const { email } = values;
    try {
      const payload = {
        email_address: email,
      };

      // 要 post 藍新金流

      // await axios.post('/.netlify/functions/add-email-subscriber', payload);
      reset();
      // toast({
      //   title: 'Subscribed Successfully',
      //   description: 'GET OUR NEWSLETTER',
      //   status: 'success',
      //   duration: 2000,
      //   isClosable: true,
      // });
    } catch (error) {
      reset();
      // toast({
      //   title: 'Already Subscribed',
      //   description: `${email} is already a list member`,
      //   status: 'error',
      //   duration: 2000,
      //   isClosable: true,
      // });
    }
  }

  return (
    <Flex w="100%" justify="center">
      <Flex
        w={{ base: '100%', lg: '80%' }}
        maxW="1200px"
        pt="120px"
        direction="column"
      >
        <Heading>購票</Heading>
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <VStack>
            <FormControl htmlFor="name" isInvalid={errors.name} maxW="500px">
              <FormLabel htmlFor="name">
                <Text textStyle="text01">姓名</Text>
              </FormLabel>
              <Input
                placeholder="Enter your name"
                {...register('name', {
                  required: 'This is required',
                  // pattern: {
                  //   value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  //   message: 'invalid email address',
                  // },
                })}
                py="24px"
                // boxShadow="0 0 5px 0 #00ffb0"
                fontSize="20px"
                // bg="rgba(0,0,0,0.5)"
                borderColor="black"
                // _focus={{ boxShadow: '0 0 20px 0 #EA593D' }}
              />
              <FormErrorMessage>
                {errors.name && errors.name.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl htmlFor="email" isInvalid={errors.email} maxW="500px">
              <FormLabel htmlFor="email">
                <Text textStyle="text01">信箱</Text>
              </FormLabel>
              <Input
                placeholder="Enter your email"
                {...register('email', {
                  required: 'This is required',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'invalid email address',
                  },
                })}
                py="24px"
                // boxShadow="0 0 5px 0 #00ffb0"
                fontSize="20px"
                borderColor="black"
                // _focus={{ boxShadow: '0 0 20px 0 #00ffb0' }}
              />
              <FormErrorMessage>
                {errors.email && errors.email.message}
              </FormErrorMessage>
            </FormControl>
          </VStack>
          <Button
            isLoading={isSubmitting}
            type="submit"
            bg="green.500"
            color="black"
            mt="40px"
            border="1px"
            fontSize="20px"
            py="22px"
            px="24px"
            textTransform="uppercase"
            // _hover={{
            //   bg: 'unset',
            //   border: '1px solid #00FFB0',
            //   color: 'green.500',
            // }}
          >
            購票
          </Button>
        </form>
      </Flex>
    </Flex>
  );
};

export default Form;
