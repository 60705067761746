import { Icon } from '@chakra-ui/react';

export function SupportIcon(props) {
  return (
    <Icon viewBox="0 0 120 120" fill="none" {...props}>
      <path
        d="M90.2407 68.2748C83.8785 68.2808 77.6489 70.0945 72.2777 73.5045C66.9065 76.9145 62.6148 81.7805 59.9025 87.5356C57.1901 81.7805 52.8984 76.9145 47.5272 73.5045C42.156 70.0945 35.9264 68.2808 29.5642 68.2748H12V71.4683C12 89.9578 27.0422 105 45.5317 105H74.2732C92.7627 105 107.805 89.9578 107.805 71.4683V68.2748H90.2407ZM45.5317 98.613C31.6438 98.613 20.1586 88.1305 18.5738 74.6618H29.5642C43.4522 74.6618 54.9374 85.1442 56.5221 98.613H45.5317ZM74.2732 98.613H63.2828C64.8675 85.1442 76.3528 74.6618 90.2407 74.6618H101.231C99.6463 88.1305 88.1611 98.613 74.2732 98.613Z"
        fill="#EA593D"
      />
      <path
        d="M42.5872 60.0461C43.1912 63.6374 44.9044 66.9495 47.4866 69.5175L48.4221 70.4527H49.7448C53.3865 70.4629 56.94 69.3325 59.9065 67.2201C62.8729 69.3327 66.4264 70.4634 70.0682 70.4535H71.3909L72.3264 69.5183C74.9085 66.9503 76.6218 63.6382 77.2258 60.0469C80.8171 59.4429 84.1291 57.7296 86.6971 55.1475L87.6324 54.212V52.8893C87.6427 49.2473 86.5123 45.6935 84.3998 42.7268C86.5124 39.7604 87.6431 36.2069 87.6332 32.5651V31.2424L86.6979 30.3069C84.1299 27.7248 80.8179 26.0115 77.2266 25.4075C76.6226 21.8162 74.9093 18.5042 72.3272 15.9362L71.3917 15.0009H70.069C66.427 14.9906 62.8732 16.121 59.9065 18.2335C56.9401 16.1209 53.3866 14.9902 49.7448 15.0001H48.4221L47.4866 15.9354C44.9044 18.5034 43.1912 21.8154 42.5872 25.4067C38.9959 26.0107 35.6838 27.724 33.1158 30.3061L32.1806 31.2416V32.5643C32.1703 36.2063 33.3007 39.7601 35.4132 42.7268C33.3005 45.6932 32.1698 49.2467 32.1798 52.8885V54.2112L33.115 55.1467C35.6833 57.729 38.9956 59.4423 42.5872 60.0461ZM41.8415 40.4688C40.0703 38.7083 38.9451 36.4016 38.648 33.9221C40.6113 32.3784 43.0382 31.5424 45.5358 31.5496H48.7293V28.3561C48.722 25.8588 49.5578 23.4321 51.1012 21.4689C53.5807 21.766 55.8874 22.8912 57.6479 24.6624L59.9065 26.919L62.1645 24.661C63.925 22.8899 66.2317 21.7647 68.7112 21.4675C70.2551 23.431 71.0911 25.8582 71.0837 28.3561V31.5496H74.2772C76.7745 31.5424 79.2012 32.3781 81.1644 33.9215C80.8672 36.401 79.742 38.7077 77.9709 40.4682L75.7143 42.7268L77.9725 44.985C79.7436 46.7455 80.8689 49.0522 81.166 51.5317C79.2024 53.0756 76.7751 53.9115 74.2772 53.904H71.0837V57.0975C71.0909 59.5948 70.2552 62.0215 68.7118 63.9847C66.2323 63.6876 63.9256 62.5624 62.1651 60.7912L59.9065 58.5346L57.6483 60.792C55.8878 62.5632 53.5811 63.6884 51.1016 63.9855C49.5579 62.0221 48.722 59.5951 48.7293 57.0975V53.904H45.5358C43.0385 53.9112 40.6118 53.0755 38.6486 51.5321C38.9457 49.0526 40.0709 46.7459 41.8421 44.9854L44.0987 42.7268L41.8415 40.4688Z"
        fill="#EA593D"
      />
    </Icon>
  );
}
