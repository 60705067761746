import { useTranslation } from 'react-i18next';

import {
  Text,
  Flex,
  Grid,
  GridItem,
  Button,
  VStack,
  Link,
} from '@chakra-ui/react';

import Counter from 'components/Counter';

const AchievementCard = props => {
  return (
    <GridItem
      as={Flex}
      minH={{ base: '100px', lg: '200px' }}
      borderRadius={{ base: 'cardMd', lg: 'cardLg' }}
      colSpan={{ base: 4, lg: 2 }}
      bgColor="bg-box"
      direction="column"
      align="center"
      justify="center"
      p="12px"
    >
      {props.children}
    </GridItem>
  );
};

const AchievementsSection = () => {
  const { t } = useTranslation();
  return (
    <Flex
      w="100%"
      direction="column"
      align="center"
      pt={{ base: '80px', lg: '100px' }}
      pb={{ base: '0', lg: '214px' }}
    >
      <VStack
        spacing={{ base: '40px', lg: '100px' }}
        w={{ base: '100%', lg: '100%' }}
        px={{ base: '24px', lg: '32px' }}
        maxW="1200px"
        direction="column"
        align="center"
      >
        <Text textStyle="heading01">Achievements</Text>
        <Grid
          templateColumns="repeat(4, 1fr)"
          w="100%"
          gap={{ base: '20px', lg: '40px' }}
        >
          <GridItem
            as={Flex}
            colSpan={4}
            h={{ base: '180px', lg: '360px' }}
            bgColor="bg-box"
            borderRadius={{ base: 'cardMd', lg: 'cardLg' }}
            align="center"
            justify="center"
            direction="column"
            px="12px"
          >
            <Counter
              from={9999}
              to={12000}
              isPlus={true}
              fontSize={{ base: '60px', lg: '120px' }}
              textStyle="specialLg"
              color="brand-color"
            />
            <Text textStyle="content01" px="40px" textAlign="center">
              {t('achievement01')}
            </Text>
          </GridItem>
          <AchievementCard>
            <Counter
              from={500}
              to={1000}
              isPlus={true}
              textStyle="heading01"
              fontSize={{ base: '60px', lg: '80px' }}
              color="brand-color"
            />
            <Text textStyle="content01" px="40px" textAlign="center">
              {t('achievement02')}
            </Text>
          </AchievementCard>
          <AchievementCard>
            <Flex textStyle="heading01" color="brand-color">
              <Counter
                from={0}
                to={451}
                textStyle="heading01"
                fontSize={{ base: '60px', lg: '80px' }}
                color="brand-color"
              />
            </Flex>
            <Text textStyle="content01" px="40px" textAlign="center">
              {t('achievement03')}
            </Text>
          </AchievementCard>
        </Grid>
        <Button
          as={Link}
          variant="primary"
          href="https://www.accupass.com/go/ubctaiwan"
          isExternal
        >
          Go to ACCUPASS
        </Button>
      </VStack>
    </Flex>
  );
};

export default AchievementsSection;
