import { motion } from 'framer-motion';

const CollapseIconMotion = props => {
  const { isActive } = props;

  return (
    <motion.svg
      viewport={{ once: false, amount: 0.6 }}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      initial="hidden"
      whileInView="visible"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.path
        d="M3.51562 12H20.4862"
        stroke="#EA593D"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <motion.path
        animate={{ opacity: isActive ? 0 : 1 }}
        d="M12 20.4852L12 3.51467"
        stroke="#EA593D"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </motion.svg>
  );
};

export default CollapseIconMotion;
