import { Icon } from '@chakra-ui/react';

export function DomeIcon(props) {
  return (
    <Icon viewBox="0 0 450 550" width="450" height="550" fill="none" {...props}>
      <path
        d="M0 225C0 100.736 100.736 0 225 0V0C349.264 0 450 100.736 450 225V490.196C450 495.611 445.611 500 440.196 500H9.80393C4.38937 500 0 495.611 0 490.196V225Z"
        fill="white"
        opacity=".8"
      />
    </Icon>
  );
}
