import { Icon } from '@chakra-ui/react';

export function LearnIcon(props) {
  return (
    <Icon viewBox="0 0 120 120" fill="none" {...props}>
      <path
        d="M59.6954 105H60.9734C66.3423 104.994 71.4896 102.859 75.286 99.0622C79.0825 95.2659 81.2179 90.1188 81.224 84.75V74.3078C85.1115 71.1868 88.2481 67.2324 90.4021 62.7365C92.5561 58.2407 93.6725 53.3184 93.6687 48.3332C93.6687 29.9533 78.715 15 60.3344 15C41.9538 15 27 29.9533 27 48.3332C26.9962 53.3184 28.1126 58.2407 30.2666 62.7366C32.4207 67.2324 35.5574 71.1869 39.4449 74.3078V84.75C39.4509 90.1188 41.5864 95.2659 45.3828 99.0622C49.1792 102.859 54.3264 104.994 59.6954 105ZM60.9734 99H59.6954C56.8409 98.9964 54.0531 98.1369 51.6922 96.5325C49.3313 94.9281 47.5058 92.6526 46.4516 90H74.2173C73.1631 92.6526 71.3376 94.9282 68.9767 96.5325C66.6157 98.1369 63.8279 98.9964 60.9734 99ZM75.2238 84H45.4451V76.5H75.2238V84ZM33.0002 48.3332C33.0002 33.2625 45.2623 21 60.3344 21C75.4064 21 87.6686 33.2625 87.6686 48.3332C87.6594 52.6343 86.6396 56.873 84.6914 60.7076C82.7431 64.5422 79.921 67.8653 76.4525 70.4089L76.3286 70.5H44.3401L44.2155 70.4089C40.7472 67.8652 37.9252 64.542 35.9771 60.7075C34.029 56.8729 33.0093 52.6342 33.0002 48.3332Z"
        fill="#EA593D"
      />
    </Icon>
  );
}
