import { useTranslation } from 'react-i18next';

import {
  Text,
  Flex,
  VStack,
  SimpleGrid,
  useColorModeValue,
} from '@chakra-ui/react';

import roundBgBack from 'assets/images/roundBgBack.svg';
import roundBgBackDark from 'assets/images/roundBgBackDark.svg';

import {
  LearnIcon,
  SupportIcon,
  ShareIcon,
  ConnectIcon,
  SpiralIcon,
} from 'components/icons';

const VALUE_LIST = [
  {
    title: 'valueTitle01',
    content: 'valueContent01',
    icon: <LearnIcon />,
  },
  {
    title: 'valueTitle02',
    content: 'valueContent02',
    icon: <SupportIcon />,
  },
  {
    title: 'valueTitle03',
    content: 'valueContent03',
    icon: <ShareIcon />,
  },
  {
    title: 'valueTitle04',
    content: 'valueContent04',
    icon: <ConnectIcon />,
  },
];

const ValueCard = props => {
  const { icon, title, content } = props;
  const { t } = useTranslation();
  return (
    <Flex direction={{ base: 'column', lg: 'row' }} align="center">
      <Text
        fontSize={{ base: '80px', lg: '120px' }}
        lineHeight={{ base: '80px', lg: '120px' }}
      >
        {icon}
      </Text>
      <VStack
        h="100%"
        pl={{ base: 0, lg: '24px' }}
        align={{ base: 'center', lg: 'flex-start' }}
        spacing="16px"
      >
        <Text textStyle="heading03">{t(title)}</Text>
        <Text textStyle="content02" maxW="360px">
          {t(content)}
        </Text>
      </VStack>
    </Flex>
  );
};

const ValuesSection = () => {
  const bg = useColorModeValue(roundBgBack, roundBgBackDark);

  return (
    <Flex
      w="100%"
      justify="center"
      direction="column"
      align="center"
      position="relative"
      bgImage={bg}
      bgPosition="bottom  center"
      bgRepeat="no-repeat"
      bgSize="cover"
      pt={{ base: '80px', lg: '150px' }}
      pb={{ base: '80px', lg: '160' }}
    >
      <Flex
        w={{ base: '100%', lg: '80%' }}
        maxW="1200px"
        px={{ base: '24px', lg: '0' }}
        justify="center"
        align="center"
        direction="column"
        position="relative"
      >
        <SpiralIcon
          w={{ base: '120px', lg: '280px' }}
          position="absolute"
          right={{ base: '20px', lg: '-10px' }}
          // bottom="-300px"
          bottom={{ base: '-150px', lg: '-300px' }}
        />
        <Text textStyle="heading01" pb={{ base: '40px', lg: '100px' }}>
          Values
        </Text>
        <SimpleGrid
          columns={2}
          spacingX={{ base: '20px', lg: '112px' }}
          spacingY={{ base: '40px', lg: '56px' }}
        >
          {VALUE_LIST.map(item => (
            <ValueCard key={item.title} {...item} />
          ))}
        </SimpleGrid>
      </Flex>
    </Flex>
  );
};

export default ValuesSection;
