import { useTranslation } from 'react-i18next';

import { Text, Flex, VStack, useColorModeValue } from '@chakra-ui/react';
import roundBgBack from 'assets/images/roundBgBack.svg';
import roundBgBackDark from 'assets/images/roundBgBackDark.svg';
import { Container } from 'components/layouts';

import CharityMotion from 'components/CharityMotion';

const CHARITY_LIST = [
  {
    title: 'Donation',
    content: 'charityDonation',
  },
  {
    title: 'Wishlist',
    content: 'charityWishlist',
  },
];

const CharityCard = props => {
  const { title, content } = props;
  const { t } = useTranslation();
  return (
    <VStack
      w="100%"
      spacing={{ base: '10px', lg: '20px' }}
      align={{ base: 'center', lg: 'flex-start' }}
    >
      <Text textStyle="heading04">{title}</Text>
      <Text textStyle="content02">{t(content)}</Text>
    </VStack>
  );
};

const CharitySection = () => {
  const bg = useColorModeValue(roundBgBack, roundBgBackDark);

  return (
    <Flex
      py={{ base: '80px', lg: '200px' }}
      w="100%"
      align="center"
      bgImage={bg}
      bgPosition="bottom  center"
      bgRepeat="no-repeat"
      bgSize="cover"
      justify="center"
    >
      <Container direction={{ base: 'column-reverse', lg: 'row' }}>
        <Flex
          justify="center"
          w={{ base: '100%', lg: '60%' }}
          mt={{ base: '40px', lg: '0' }}
        >
          <CharityMotion />
        </Flex>
        <Flex
          w={{ base: '100%', lg: '40%' }}
          direction="column"
          align={{ base: 'center', lg: 'flex-start' }}
        >
          <Text pb={{ base: '40px', lg: '80px' }} textStyle="heading01">
            Charity
          </Text>
          <VStack align="flex-start" spacing={{ base: '20px', lg: '40px' }}>
            {CHARITY_LIST.map(item => (
              <CharityCard key={item.title} {...item} />
            ))}
          </VStack>
        </Flex>
      </Container>
    </Flex>
  );
};

export default CharitySection;
