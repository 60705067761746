import { Flex, Text, SimpleGrid, Image, Link } from '@chakra-ui/react';
import { Container } from 'components/layouts';

import AAPD from 'assets/images/support/AAPD.png';
import ADPlist from 'assets/images/support/ADPlist.png';
import GDSC from 'assets/images/support/GDSC.png';
import Pickup from 'assets/images/support/Pickup.png';
import Reborn from 'assets/images/support/Reborn.png';
import curators from 'assets/images/support/curators.png';
import TuringCerts from 'assets/images/support/TuringCerts.png';
import cross from 'assets/images/support/cross.png';
import JiYuan from 'assets/images/support/JiYuan.png';
import mutahead from 'assets/images/support/mutahead.png';
import WTM from 'assets/images/support/WTM.png';
import XChange from 'assets/images/support/XChange.png';
import XChangeCollege from 'assets/images/support/XChangeCollege.png';
import Yourator from 'assets/images/support/Yourator.png';
import Nomads from 'assets/images/support/Nomads.png';
import soso from 'assets/images/support/soso.png';
import explosive from 'assets/images/support/explosive.png';
import Reach from 'assets/images/support/Reach.png';
import oneDayTest from 'assets/images/support/oneDayTest.png';
import complete from 'assets/images/support/complete.png';

const SUPPORT_LIST = [
  {
    name: 'AAPD',
    image: AAPD,
    link: 'https://portaly.cc/aapdgo',
  },
  {
    name: 'ADPlist',
    image: ADPlist,
    link: 'https://www.facebook.com/adplist.taipei',
  },
  {
    name: 'GDSC',
    image: GDSC,
    link: 'https://www.instagram.com/gdsc.nccu',
  },
  {
    name: 'Pickup',
    image: Pickup,
    link: 'https://portaly.cc/PickupDesign',
  },
  {
    name: 'Reborn',
    image: Reborn,
    link: 'https://reborn.in/',
  },
  {
    name: 'curators',
    image: curators,
    link: 'https://thecurators.super.site/',
  },
  {
    name: 'TuringCerts',
    image: TuringCerts,
    link: 'https://certs.turingchain.tech/',
  },
  {
    name: 'cross',
    image: cross,
    link: 'https://crossover.vip/',
  },
  {
    name: 'JiYuan',
    image: JiYuan,
    link: 'https://www.chiyuantea.com/',
  },
  {
    name: 'mutahead',
    image: mutahead,
    link: 'https://mutahead.com/',
  },
  {
    name: 'WTM',
    image: WTM,
    link: 'https://www.facebook.com/wtmtaiwan',
  },
  {
    name: 'XChange',
    image: XChange,
    link: 'https://bit.ly/3YxFzwO',
  },
  {
    name: 'XChangeCollege',
    image: XChangeCollege,
    link: 'https://www.facebook.com/groups/xchange.academy/',
  },
  {
    name: 'Yourator',
    image: Yourator,
    link: 'https://www.yourator.co/',
  },
  {
    name: 'Nomads',
    image: Nomads,
    link: 'https://designnomads.co',
  },
  {
    name: 'soso',
    image: soso,
    link: 'https://uiuxdesign.tw/',
  },
  {
    name: 'explosive',
    image: explosive,
    link: 'https://atomskool.org/',
  },
  {
    name: 'Reach',
    image: Reach,
    link: 'https://www.facebook.com/ReachExperienceDesign',
  },
  {
    name: 'oneDayTest',
    image: oneDayTest,
    link: 'https://www.facebook.com/oneday.software',
  },
  {
    name: 'complete',
    image: complete,
    link: 'https://kanshoku.co/',
  },
];

const SupportsSection = () => {
  return (
    <Flex w="100%" justify="center" py={{ base: '80px', lg: '180px' }}>
      <Container maxW="unset" w="100%" px={{ base: '24px', lg: '80px' }}>
        <Text textStyle="heading01" pb={{ base: '40px', lg: '100px' }}>
          Supporters
        </Text>
        <SimpleGrid
          columns={{ base: 4, lg: 10 }}
          spacing={{ base: '10px', lg: '40px' }}
        >
          {SUPPORT_LIST.map(item => (
            <Link isExternal href={item.link} key={item.name}>
              <Image
                display="block"
                rounded="lg"
                bgColor="white"
                filter="saturate(0) opacity(.75)"
                _hover={{
                  filter: 'saturate(1) opacity(1)',
                }}
                transition="all 250ms linear"
                alt={item.name}
                src={item.image}
              />
            </Link>
          ))}
        </SimpleGrid>
      </Container>
    </Flex>
  );
};

export default SupportsSection;
