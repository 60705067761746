import { useTranslation } from 'react-i18next';

import { Text, Flex, VStack, SimpleGrid } from '@chakra-ui/react';

import HowWeWorkMotion from 'components/HowWeWorkMotion';
import WorkArrowMotion from 'components/WorkArrowMotion';

const HOW_WE_WORK_LIST = [
  {
    title: 'Guide',
    content: 'howWeWorkContent01',
  },
  {
    title: 'Mentor',
    content: 'howWeWorkContent02',
  },
  {
    title: 'Sharing',
    content: 'howWeWorkContent03',
  },
  {
    title: 'Report',
    content: 'howWeWorkContent04',
  },
];

const Card = props => {
  const { t } = useTranslation();
  const { title, content, order } = props;
  const isLast = order === 3;
  const isOdd = (order + 1) % 2 !== 0;

  return (
    <Flex
      bgColor="bg-box"
      p={{ base: '20px 22px', lg: '40px 140px' }}
      rounded={{ base: 'cardMd', lg: 'cardLg' }}
      w="100%"
      align="center"
      justify="center"
      position="relative"
    >
      {!isLast && (
        <Flex
          color="text-title"
          zIndex="1"
          display={{ base: 'flex', lg: 'none' }}
          position="absolute"
          right={isOdd && '20px'}
          left={!isOdd && '20px'}
          bottom="-40px"
        >
          <WorkArrowMotion delay={order} isOdd={isOdd} />
        </Flex>
      )}
      <VStack spacing="20px">
        <Text textStyle="heading02" color="brand-color">
          {title}
        </Text>
        <Text textStyle="content02" textAlign={{ base: 'center', lg: 'left' }}>
          {t(content)}
        </Text>
      </VStack>
    </Flex>
  );
};

const HowWeWorkSection = () => {
  const { t } = useTranslation();
  return (
    <Flex
      w="100%"
      py={{ base: '80px', lg: '0' }}
      pb={{ base: '80px', lg: '140px' }}
      justify="center"
    >
      <VStack
        spacing={{ base: '40px', lg: '100px' }}
        w={{ base: '100%', lg: '100%' }}
        px={{ base: '24px', lg: 0 }}
        maxW="1200px"
        direction="column"
        align="center"
      >
        <VStack>
          <Text textStyle="heading01">How We Work</Text>
          <Text textStyle="content01" textAlign="center">
            {t('howWeWorkIntro')}
          </Text>
        </VStack>
        <SimpleGrid
          w="100%"
          columns={{ base: 1, lg: 2 }}
          spacing={{ base: '20px', lg: '40px' }}
          position="relative"
        >
          <Flex
            zIndex="1"
            display={{ base: 'none', lg: 'flex' }}
            position="absolute"
            top="30%"
            right="0"
            left="0"
            justify="center"
            m="0 auto"
          >
            <HowWeWorkMotion />
          </Flex>
          {HOW_WE_WORK_LIST.map((item, order) => (
            <Card key={item.title} {...item} order={order} />
          ))}
        </SimpleGrid>
      </VStack>
    </Flex>
  );
};

export default HowWeWorkSection;
