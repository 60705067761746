import { Icon } from '@chakra-ui/react';

export function GearIcon(props) {
  return (
    <Icon viewBox="0 0 100 100" fill="none" {...props}>
      <path
        d="M100.349 50.6941C100.349 55.5465 85.7621 57.9194 84.4028 62.3036C83.0435 66.6877 93.4208 77.7544 90.7935 81.532C88.1662 85.3096 75.0196 78.3077 71.3778 81.0851C67.7361 83.8624 70.12 99.1217 65.7986 100.59C61.4773 102.059 54.9749 88.2465 50.3492 88.2465C45.7235 88.2465 39.0691 102.016 34.8998 100.59C30.5683 99.111 32.9218 83.8305 29.3206 81.0851C25.7195 78.3397 12.5525 85.3521 9.90492 81.532C7.25733 77.7118 17.7057 66.8473 16.2957 62.3036C14.8856 57.7598 0.359375 55.5465 0.359375 50.6941C0.359375 45.8418 14.9465 43.4689 16.3058 39.0847C17.6651 34.7006 7.28776 23.6339 9.91507 19.8563C12.5424 16.0787 25.6891 23.0805 29.3308 20.3032C32.9725 17.5259 30.5785 2.27724 34.8998 0.808775C39.2212 -0.659694 45.7235 13.1524 50.3492 13.1524C54.9749 13.1524 61.6294 -0.61713 65.8088 0.808775C70.1403 2.28788 67.7868 17.5685 71.388 20.3139C74.9891 23.0593 88.1561 16.0468 90.8037 19.8669C93.4513 23.6871 83.0029 34.5516 84.4129 39.0954C85.823 43.6391 100.359 45.8525 100.359 50.7048L100.349 50.6941Z"
        fill="currentColor"
      />
    </Icon>
  );
}
