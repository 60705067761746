import { Icon } from '@chakra-ui/react';

export function DotIcon(props) {
  return (
    <Icon viewBox="0 0 30 30" fill="none" {...props}>
      <rect
        x="7"
        y="15"
        width="12"
        height="12"
        transform="rotate(-45 7 15)"
        fill="#EA593D"
      />
    </Icon>
  );
}
