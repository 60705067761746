import {
  extendTheme,
  theme as base,
  theme as origTheme,
} from '@chakra-ui/react';
import colors from './colors';
import textStyles from './textStyles';
import Button from './components/button';

import semanticTokens from 'theme/semanticTokens';

const config = {
  // initialColorMode: 'light',
  useSystemColorMode: true,
};

const theme = extendTheme({
  config,
  textStyles,
  // layerStyles,
  semanticTokens,
  colors,
  styles: {
    global: {
      body: {
        background: 'bg-default',
        overflowX: 'hidden',
        transitionProperty: 'all',
        transitionDuration: 'normal',
      },
    },
  },
  fonts: {
    heading: `'Montserrat','-apple-system','BlinkMacSystemFont ','PingFang TC','NotoSansCJK','Microsoft JhengHei',${base.fonts?.body}`,
    body: `'Montserrat','-apple-system','BlinkMacSystemFont ','PingFang TC','NotoSansCJK','Microsoft JhengHei',${base.fonts?.body}`,
  },
  components: {
    Button,
    // Form,
    // Input,
    // Link,
    Link: {
      baseStyle: {
        textDecoration: 'none',
        _hover: {
          textDecoration: 'none',
        },
        _focus: {
          outline: '0px',
          boxShadow: 'unset',
        },
      },
    },
    components: {
      Alert: {
        variants: {
          toast: {
            container: {
              color: 'white',
              bg: 'red.500',
              bgColor: 'orange.500 !important',
            },
          },
        },
      },
    },
  },
  // breakpoints: {
  //   xs: '320px',
  //   sm: '768px',
  //   md: '991px',
  //   lg: '1200px',
  //   xl: '1300px',
  //   '2xl': '1536px',
  // },
});

export default theme;
