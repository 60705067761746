import { useTranslation } from 'react-i18next';

import roundBg from 'assets/images/roundBg.svg';
import roundBgDark from 'assets/images/roundBgDark.svg';

import { DotIcon } from 'components/icons';
import LearnMotion from 'components/LearnMotion';

import {
  Text,
  Flex,
  VStack,
  HStack,
  useColorModeValue,
} from '@chakra-ui/react';

import { Container } from 'components/layouts';

const LEARN_CONTENT = [
  {
    title: 'Certification',
    content: [
      'whatWillLearnCertification01',
      'whatWillLearnCertification02',
      'whatWillLearnCertification03',
    ],
  },
  {
    title: 'Hands-on Experience',
    content: [
      'whatWillLearn01Experience01',
      'whatWillLearn01Experience02',
      'whatWillLearn01Experience03',
    ],
  },
];

const LearnList = props => {
  const { title, content } = props;
  const { t } = useTranslation();
  return (
    <VStack align={{ base: 'center', lg: 'flex-start' }}>
      <Text textStyle="heading04" fontWeight="700">
        {title}
      </Text>
      <VStack w="100%" spacing="8px" align="flex-start">
        {content.map(item => (
          <HStack key={item}>
            <DotIcon />
            <Text textStyle="content02">{t(item)}</Text>
          </HStack>
        ))}
      </VStack>
    </VStack>
  );
};

const WhatYouWillLearnSection = () => {
  const bg = useColorModeValue(roundBg, roundBgDark);

  return (
    <Flex
      py={{ base: '80px', lg: '200px' }}
      bgImage={bg}
      bgPosition="top  center"
      bgRepeat="no-repeat"
      bgSize="cover"
      justify="center"
    >
      <Container direction={{ base: 'column', lg: 'row' }}>
        <Flex
          w={{ base: '100%', lg: '40%' }}
          direction="column"
          // align="flex-start"
          align={{ base: 'center', lg: 'flex-start' }}
        >
          <Text pb={{ base: '40px', lg: '80px' }} textStyle="heading01">
            What You <br /> Will Learn
          </Text>
          <VStack align="flex-start" spacing={{ base: '20px', lg: '40px' }}>
            {LEARN_CONTENT.map(item => (
              <LearnList key={item.title} {...item} />
            ))}
          </VStack>
        </Flex>
        <Flex
          justify="center"
          w={{ base: '100%', lg: '60%' }}
          mt={{ base: '40px', lg: '0' }}
        >
          <LearnMotion />
        </Flex>
      </Container>
    </Flex>
  );
};

export default WhatYouWillLearnSection;
