import Header from 'components/header/Header';
import { Button } from '@chakra-ui/react';
import Form from 'sections/Form';

export function ActivityPage() {
  return (
    <>
      <Header />
      <Form />
    </>
  );
}
