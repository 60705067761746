import { Icon } from '@chakra-ui/react';

export function ArrowDownIcon(props) {
  return (
    <Icon viewBox="0 0 74 90" fill="none" {...props}>
      <path
        d="M2 52.4493C16.06 52.4493 37 87.4493 37 87.4493C37 87.4493 56.9906 52.4493 72 52.4493"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37 2V87.3514"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinecap="round"
      />
    </Icon>
  );
}
