import { Icon } from '@chakra-ui/react';

export function FlowIcon(props) {
  return (
    <Icon viewBox="0 0 185 185" fill="none" {...props}>
      <g clipPath="url(#clip0_2253_16586)">
        <path
          d="M106.321 84.8947C213.491 94.4778 193.713 158.677 99.7261 106.312C90.143 213.482 25.9442 193.704 78.3093 99.7172C-28.8613 90.134 -9.09614 25.9419 84.8969 78.2876C94.48 -28.883 158.679 -9.1051 106.314 84.8819L106.321 84.8947Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2253_16586">
          <rect
            width="136.584"
            height="136.584"
            fill="white"
            transform="translate(0 63.8823) rotate(-27.8861)"
          />
        </clipPath>
      </defs>
    </Icon>
  );
}
