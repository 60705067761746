import { useState, useEffect } from 'react';

import { motion, useMotionValueEvent, useScroll } from 'framer-motion';

import { Flex, useColorModeValue } from '@chakra-ui/react';
import { FlowIcon, GearIcon } from 'components/icons';

import learnImage from 'assets/images/learnImage.png';
import learnImageDark from 'assets/images/learnImageDark.png';

const MotionFLex = motion(Flex);

const LearnMotion = () => {
  const [degree, setDegree] = useState(0);
  const { scrollY } = useScroll();
  useMotionValueEvent(scrollY, 'change', latest => {
    setDegree((latest / 360) * 75);
  });
  const bg = useColorModeValue(learnImage, learnImageDark);

  return (
    <Flex
      bgImage={bg}
      bgRepeat="no-repeat"
      w={{ base: '320px', lg: '600px' }}
      h={{ base: '320px', lg: '600px' }}
      bgSize="contain"
      position="relative"
    >
      <MotionFLex
        top="0"
        left={{ base: '0', lg: '40px' }}
        position="absolute"
        w={{ base: '90px', lg: '120px' }}
        h={{ base: '90px', lg: '120px' }}
        fontSize={{ base: '90px', lg: '120px' }}
        color="item-bg"
        animate={{ rotate: degree }}
        transition={{
          type: 'spring',
          // damping: 3,
          stiffness: 50,
          restDelta: 0.001,
        }}
      >
        <FlowIcon />
      </MotionFLex>
      <MotionFLex
        position="absolute"
        bottom={{ base: '10px', lg: '0px' }}
        left={{ base: '20px', lg: '120px' }}
        w={{ base: '50px', lg: '100px' }}
        h={{ base: '50px', lg: '100px' }}
        fontSize={{ base: '50px', lg: '100px' }}
        color="item-bg"
        animate={{ rotate: degree + 20 }}
        transition={{
          type: 'spring',
          // damping: 3,
          stiffness: 50,
          restDelta: 0.001,
        }}
      >
        <GearIcon />
      </MotionFLex>
    </Flex>
  );
};

export default LearnMotion;
