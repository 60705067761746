const colors = {
  grey: {
    700: '#202020',
    600: '#323232',
    500: '#666666',
    400: '#999999',
    300: '#323232',
    200: '#EFEFEF',
    100: '#F0F0F0',
  },
  brandGrey: {
    500: '#EFE5E5',
    400: '#EFEFEF',
  },
  orange: {
    500: '#EA593D',
  },
};

export default colors;
