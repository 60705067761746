import Lottie from 'lottie-react';
import { Text, Flex, Heading, Image, Link, VStack } from '@chakra-ui/react';

import highlight from 'assets/lottie/highlight.json';
import loading from 'assets/lottie/loading.json';

import { motion } from 'framer-motion';

import heroText from 'assets/images/heroText.svg';
import DrawMotion from 'components/DrawMotion';

import {
  ArrowDownIcon,
  HeroText,
  HeroTextSm,
  HeroBookIcon,
} from 'components/icons';

const MotionFlex = motion(Flex);

const Hero = () => {
  return (
    <Flex
      bgColor="bg-secondary"
      justify="center"
      align="center"
      position="relative"
      overflow="hidden"
      w="100%"
      py={{ base: '120px', lg: '200px' }}
    >
      <MotionFlex
        animate={{ y: 25 }}
        transition={{ repeat: Infinity, repeatType: 'reverse', duration: 0.75 }}
        color="text-title"
        fontSize={{ base: '40px', lg: '70px' }}
        w={{ base: '40px', lg: '70px' }}
        position="absolute"
        bottom={{ base: '30px', lg: '50px' }}
        left="0"
        right="0"
        margin="0 auto"
      >
        <ArrowDownIcon />
      </MotionFlex>

      <Flex
        w={{ base: '90%', lg: '80%' }}
        position="relative"
        align="center"
        direction={{ base: 'column', lg: 'row' }}
      >
        <Flex
          w="310px"
          position="relative"
          display={{ base: 'flex', lg: 'none' }}
        >
          <DrawMotion
            position="absolute"
            top="45%"
            w={{ base: '100px', lg: '400px' }}
            h={{ base: '100px', lg: '400px' }}
            left="-5%"
          />
          <Flex
            position="absolute"
            top="45%"
            w={{ base: '100px', lg: '400px' }}
            h={{ base: '100px', lg: '400px' }}
            left="-5%"
          >
            {/* <Lottie animationData={highlight} loop={true} /> */}
          </Flex>
          <HeroTextSm w="310px" pb="40px" />
        </Flex>
        <Flex display={{ base: 'none', lg: 'flex' }} w="80%" maxW="1200px">
          <HeroText />
          <DrawMotion
            position="absolute"
            top={{ base: '32%', lg: '42%' }}
            w="18%"
            left={{ base: '0%', lg: '-3.4%' }}
          />
          {/* <Flex
            position="absolute"
            top={{ base: '32%', lg: '32%' }}
            w="22%"
            left={{ base: '0%', lg: '-6%' }}
          >
            <Lottie animationData={highlight} loop={true} />
          </Flex> */}
        </Flex>
        <MotionFlex
          w={{ base: '100%', lg: '24%' }}
          // animate={{ y: 20, x: -10 }}
          // transition={{
          //   repeat: Infinity,
          //   repeatType: 'reverse',
          //   duration: 1.5,
          // }}
          color="text-title"
          position={{ base: 'relative', lg: 'absolute' }}
          right={{ base: '0', lg: '2%' }}
          bottom={{ base: '0', lg: '-4%' }}
          justify="center"
        >
          <HeroBookIcon w={{ base: '280px', lg: '100%' }} />
        </MotionFlex>
      </Flex>
    </Flex>
  );
};

export default Hero;
