const Button = {
  baseStyle: {
    color: 'white',
    // bgColor: 'btn-primary-bg',
    // display: 'flex',
    fontWeight: '400',
    letterSpacing: '1.5',
    borderRadius: '4px',
    fontSize: '16px',
    _focus: {
      ring: '0px',
    },
  },
  sizes: {
    // md: {
    //   w: '327px',
    //   h: '40px',
    //   fontSize: '16px',
    // },
    sm: {
      w: '155px',
      h: '40px',
      fontSize: '16px',
    },
    mini: {
      w: '112px',
      h: '40px',
      fonSize: '16px',
    },
    auto: {
      w: 'unset',
      h: 'unset',
      fonSize: 'unset',
      padding: '8px 12px',
    },
  },
  variants: {
    primary: {
      borderWidth: '1px',
      borderColor: 'btn-primary-bg',
      bgColor: 'btn-primary-bg',
      color: 'btn-primary-text',
      boxShadow: 'btn-shadow',
      fontWeight: '500',
      fontSize: '20px',
      lineHeight: '30px',
      padding: '16px 30px',
      borderRadius: 'radii-button',
      h: 'unset',
      _hover: {
        bgColor: 'btn-primary-pressed-bg',
        border: '1px solid red',
        color: 'btn-primary-pressed-text',
        _disabled: {
          opacity: '1',
          text: 'btn-primary-disabled-text',
          bgColor: 'btn-primary-disabled-bg',
        },
      },
      _active: {
        bgColor: 'btn-primary-pressed-bg',
      },
      _pressed: {
        bgColor: 'btn-primary-pressed-bg',
      },
      _disabled: {
        opacity: '1',
        text: 'btn-primary-disabled-text',
        bgColor: 'btn-primary-disabled-bg',
      },
    },
    outline: {
      borderWidth: '1px',
      color: 'white',
    },
    // secondary: {
    //   bgColor: 'btn-secondary-bg',
    //   color: 'btn-secondary-text',
    //   border: '1px solid',
    //   borderColor: 'btn-secondary-border',
    //   _hover: {
    //     bgColor: 'btn-secondary-pressed-bg',
    //     color: 'btn-secondary-pressed-text',
    //     borderColor: 'btn-secondary-pressed-border',
    //     _disabled: {
    //       opacity: '1',
    //       color: 'btn-secondary-disabled-text',
    //       bgColor: 'btn-secondary-disabled-bg',
    //       borderColor: 'btn-secondary-disabled-border',
    //     },
    //   },
    //   _active: {
    //     bgColor: 'btn-secondary-pressed-bg',
    //   },
    //   _pressed: {
    //     bgColor: 'btn-secondary-pressed-bg',
    //   },
    //   _disabled: {
    //     opacity: '1',
    //     color: 'btn-secondary-disabled-text',
    //     bgColor: 'btn-secondary-disabled-bg',
    //     borderColor: 'btn-secondary-disabled-border',
    //   },
    // },
    // cardPrimary: {
    //   bgColor: 'btn-cardPrimary-bg',
    //   color: 'btn-primary-text',
    //   _hover: {
    //     bgColor: 'btn-cardPrimary-pressed-bg',
    //     _disabled: {
    //       opacity: '1',
    //       text: 'btn-primary-disabled-text',
    //       bgColor: 'btn-primary-disabled-bg',
    //     },
    //   },
    //   _active: {
    //     bgColor: 'btn-cardPrimary-pressed-bg',
    //   },
    //   _pressed: {
    //     bgColor: 'btn-cardPrimary-pressed-bg',
    //   },
    //   _disabled: {
    //     opacity: '1',
    //     text: 'btn-primary-disabled-text',
    //     bgColor: 'btn-primary-disabled-bg',
    //   },
    // },
    // cardSecondary: {
    //   bgColor: 'btn-cardSecondary-bg',
    //   color: 'btn-cardSecondary-text',
    //   border: '1px solid',
    //   borderColor: 'btn-cardSecondary-border',
    //   _hover: {
    //     bgColor: 'btn-cardSecondary-pressed-bg',
    //     color: 'btn-cardSecondary-pressed-text',
    //     borderColor: 'btn-cardSecondary-pressed-border',
    //     _disabled: {
    //       opacity: '1',
    //       color: 'btn-secondary-disabled-text',
    //       bgColor: 'btn-secondary-disabled-bg',
    //       borderColor: 'btn-secondary-disabled-border',
    //     },
    //   },
    //   _active: {
    //     bgColor: 'btn-cardSecondary-pressed-bg',
    //   },
    //   _pressed: {
    //     bgColor: 'btn-cardSecondary-pressed-bg',
    //   },
    //   _disabled: {
    //     opacity: '1',
    //     color: 'btn-secondary-disabled-text',
    //     bgColor: 'btn-secondary-disabled-bg',
    //     borderColor: 'btn-secondary-disabled-border',
    //   },
    // },
    // disable: {
    //   bgColor: '#C4C4C4',
    // },
  },
};

export default Button;
