import { Flex } from '@chakra-ui/react';
import { motion } from 'framer-motion';

const DrawMotion = props => {
  return (
    <Flex color="text-title" {...props}>
      <motion.svg
        width="100%"
        // height="221"
        viewBox="0 0 283 221"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_2579_17408)">
          <motion.path
            opacity="0"
            initial={{ pathLength: 0, opacity: 0 }}
            animate={{
              pathLength: [0, 1, 1, 1, 1],
              opacity: [0, 1, 1, 1, 0],
              transition: {
                delay: 1,
                duration: 1.5,
                repeat: Infinity,
                type: 'tween',
              },
            }}
            d="M141.632 172.955C210.226 140.359 274.096 84.397 256.741 54.3384C239.387 24.2797 174.879 29.0335 112.659 64.9562C50.4392 100.879 14.0685 154.367 31.4228 184.426C45.9796 209.639 107.506 215.194 189.476 161.944"
            stroke="currentColor"
            strokeWidth="4"
            strokeLinecap="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_2579_17408">
            <rect width="283" height="221" fill="white" />
          </clipPath>
        </defs>
      </motion.svg>
    </Flex>
  );
};

export default DrawMotion;
