import { Icon } from '@chakra-ui/react';

export function HeroBookIcon(props) {
  return (
    <Icon viewBox="0 0 469 512" w="unset" h="unset" fill="none" {...props}>
      <path
        d="M276.834 79.944L178.983 21.6366L240.385 78.8588C246.981 85.0118 249.135 94.756 245.734 103.116L151.798 333.979L166.927 342.989C169.172 344.323 171.773 344.61 174.251 343.777C176.728 342.945 178.63 341.159 179.615 338.738L280.483 90.8396C282.135 86.7796 280.599 82.1942 276.818 79.9378L276.834 79.944Z"
        fill="#EA593D"
      />
      <path
        d="M231.469 88.4316L156.67 18.7152L197.804 90.9323C201.151 96.7977 201.603 103.746 199.057 110.002L107.03 336.172L119.248 347.556C121.392 349.567 124.28 350.356 127.167 349.725C130.038 349.088 132.329 347.163 133.437 344.442L233.626 98.212C234.996 94.8437 234.139 90.9195 231.469 88.4316Z"
        fill="#EA593D"
      />
      <path
        d="M186.45 97.391L139.775 15.4366C138.763 13.658 137.248 12.3406 135.488 11.6247C134.269 11.1291 132.935 10.9191 131.535 10.9982C128.077 11.2218 125.243 13.2761 123.94 16.4789L26.0876 256.965C25.0658 259.476 25.2546 262.287 26.589 264.652L28.6042 268.188L33.2436 276.349L73.2642 346.606C74.9688 349.613 78.0624 351.274 81.5048 351.045C84.9621 350.821 87.7968 348.767 89.1 345.564L186.952 105.078C187.974 102.567 187.785 99.7564 186.45 97.391Z"
        fill="#EA593D"
      />
      <path
        d="M395.523 130.115C394.511 128.337 392.996 127.019 391.236 126.304C390.017 125.808 388.682 125.598 387.283 125.677C383.84 125.907 380.991 127.955 379.687 131.158L377.265 137.112L424.692 181.325L395.523 130.115Z"
        fill="#EA593D"
      />
      <path
        d="M362.968 154.906L402.384 178.386L349.548 129.153C348.733 128.4 347.813 127.816 346.835 127.418C345.211 126.757 343.414 126.605 341.638 127.004C338.767 127.642 336.476 129.566 335.368 132.288L333.343 137.265L362.977 154.927L362.968 154.906Z"
        fill="#EA593D"
      />
      <path
        d="M436.887 214.169L301.868 133.751C301.472 133.519 301.054 133.297 300.633 133.125C298.692 132.336 296.581 132.266 294.551 132.947C292.073 133.78 290.172 135.565 289.187 137.986L188.318 385.885C186.666 389.945 188.202 394.531 191.968 396.781L326.99 477.235C328.709 478.267 330.634 478.665 332.553 478.429C333.145 478.354 333.724 478.222 334.314 478.023C336.791 477.191 338.693 475.406 339.678 472.985L440.552 225.071C442.21 220.996 440.668 216.425 436.887 214.169Z"
        fill="#EA593D"
      />
      <path
        d="M280.154 102.213L182.303 43.9054L243.705 101.128C250.302 107.281 252.456 117.025 249.054 125.385L155.118 356.247L170.247 365.258C172.492 366.592 175.093 366.878 177.571 366.046C180.049 365.214 181.95 363.428 182.935 361.007L283.803 113.108C285.455 109.048 283.92 104.463 280.139 102.207L280.154 102.213Z"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinejoin="round"
      />
      <path
        d="M234.789 110.7L159.991 40.984L201.125 113.201C204.472 119.066 204.923 126.015 202.378 132.27L110.35 358.441L122.568 369.825C124.713 371.836 127.6 372.625 130.487 371.994C133.359 371.357 135.65 369.432 136.757 366.711L236.946 120.481C238.317 117.112 237.459 113.188 234.789 110.7Z"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinejoin="round"
      />
      <path
        d="M189.771 119.66L143.096 37.7054C142.084 35.9268 140.568 34.6094 138.808 33.8935C137.59 33.3979 136.255 33.1879 134.855 33.267C131.398 33.4906 128.563 35.5449 127.26 38.7477L29.4079 279.233C28.3861 281.745 28.5749 284.555 29.9093 286.921L31.9245 290.457L36.5639 298.618L76.5845 368.875C78.2891 371.882 81.3828 373.543 84.8251 373.314C88.2825 373.09 91.1171 371.036 92.4203 367.833L190.272 127.347C191.294 124.836 191.105 122.025 189.771 119.66Z"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinejoin="round"
      />
      <path
        d="M398.844 152.384C397.831 150.606 396.316 149.288 394.556 148.572C393.337 148.077 392.003 147.867 390.603 147.946C387.161 148.176 384.311 150.224 383.008 153.427L380.585 159.381L428.012 203.593L398.844 152.384Z"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinejoin="round"
      />
      <path
        d="M366.288 177.174L405.704 200.655L352.869 151.422C352.053 150.669 351.133 150.085 350.156 149.687C348.531 149.026 346.734 148.874 344.959 149.273C342.087 149.91 339.796 151.835 338.689 154.557L336.663 159.534L366.297 177.196L366.288 177.174Z"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinejoin="round"
      />
      <path
        d="M440.208 236.438L305.189 156.02C304.792 155.788 304.374 155.566 303.953 155.394C302.012 154.605 299.902 154.535 297.871 155.216C295.393 156.049 293.492 157.834 292.507 160.255L191.639 408.154C189.987 412.214 191.522 416.799 195.288 419.05L330.31 499.504C332.029 500.536 333.954 500.933 335.873 500.698C336.465 500.623 337.045 500.491 337.634 500.292C340.112 499.46 342.013 497.674 342.998 495.253L443.872 247.34C445.53 243.265 443.989 238.694 440.208 236.438Z"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
