import { useState, useEffect } from 'react';
import { Text, Flex, Grid, GridItem, others } from '@chakra-ui/react';

import { motion } from 'framer-motion';

const MotionText = motion(Text);

const CounterLocalString = props => {
  const { from, to, isPlus, ...others } = props;
  const [value, setValue] = useState(from || 100);

  return (
    <MotionText
      initial={{ value: from || 100 }}
      whileInView={{ value: to || 1000 }}
      viewport={{ once: true }}
      transition={{ duration: 2 }}
      onUpdate={latest => {
        setValue(
          latest.value.toLocaleString('en-US', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })
        );
      }}
      {...others}
    >
      {value} {isPlus && '+'}
    </MotionText>
  );
};

export default CounterLocalString;
