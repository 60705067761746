import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/700.css';

import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';

import theme from 'theme/theme';

import Hero from 'sections/Hero';
import ValuesSection from 'sections/ValuesSection';
import AchievementsSection from 'sections/AchievementsSection';
import HowWeWorkSection from 'sections/HowWeWorkSection';
import WhatYouWillLearnSection from 'sections/WhatYouWillLearnSection';
import TimetableSection from 'sections/TimetableSection';
import CharitySection from 'sections/CharitySection';
import MentorSection from 'sections/MentorSection';
import SupportsSection from 'sections/SupportsSection';
import Footer from 'sections/Footer';

import Header from 'components/header/Header';

import Router from 'routes';

function App() {
  console.log(
    '%c ___  ___  ________  ________     \n|\\  \\|\\  \\|\\   __  \\|\\   ____\\    \n\\ \\  \\\\\\  \\ \\  \\|\\ /\\ \\  \\___|    \n \\ \\  \\\\\\  \\ \\   __  \\ \\  \\        \n  \\ \\  \\\\\\  \\ \\  \\|\\  \\ \\  \\____   \n   \\ \\_______\\ \\_______\\ \\_______\\ \n    \\|_______|\\|_______|\\|_______| ',
    'color: #EA593D; font-size: 14px;'
  );

  return (
    <ChakraProvider theme={theme}>
      <Router />
      {/* <Header />
      <Hero />
      <ValuesSection />
      <AchievementsSection />
      <HowWeWorkSection />
      <WhatYouWillLearnSection />
      <TimetableSection />
      <CharitySection />
      <MentorSection />
      <SupportsSection />
      <Footer /> */}
    </ChakraProvider>
  );
}

export default App;
