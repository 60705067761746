const value = {
  valueTitle01: 'Learn',
  valueTitle02: 'Support',
  valueTitle03: 'Share',
  valueTitle04: 'Connect',
  valueContent01:
    'Complete the Google UX Design Certificate with a UBC buddy and earn at least 3 verified design projects.',
  valueContent02: `At UBC, we believe in giving back to our community. That's why we're proud to donate the full amount of our revenue to Grass Book House, a NPO in Taiwan that supports children in need.`,
  valueContent03:
    'Gain valuable insights from 14 industry mentors as they share their experiences and processes for success. ',
  valueContent04:
    'Establish a personal network and improve your immediate effectiveness in the workplace through communication and partnership at UBC.',
};

const achievements = {
  achievement01: 'ACCUPASS event page views in 2 months',
  achievement02: 'Number of student registrations',
  achievement03: 'Number of final admissions',
};

const howWeWork = {
  howWeWorkIntro:
    'Our mentor system pairs senior members with junior members based on work experience, promoting collaborative learning within groups.',
  howWeWorkContent01:
    'Each week, the responsible team prepares reading briefings and facilitates group reviews of chapter highlights.',
  howWeWorkContent02: `Our industry mentors share insights and answer questions based on chapter content and students' pre-class inquiries.`,
  howWeWorkContent03:
    'Prior to the book club event, students share homework on our collaborative whiteboard, exchanging text feedback and participating in group voice discussions.',
  howWeWorkContent04:
    'Two students in each group will present their work from chapters 5-7, receiving feedback from industry mentors.',
};

const whatWillLearn = {
  whatWillLearnCertification01: 'Google UX Design Professional Certificate',
  whatWillLearnCertification02:
    'Google UX Design Professional Certificate Completion',
  whatWillLearnCertification03:
    'Online blockchain participation proof issued by the UBC Joint Study Group',
  whatWillLearn01Experience01: 'At least 3 verified design projects',
  whatWillLearn01Experience02:
    'Improved input, output, and presentation skills',
  whatWillLearn01Experience03:
    'Enhanced remote team communication and management skills',
};

const charity = {
  charityDonation: `At UBC, we believe in giving back to our community. That's why we're proud to donate the full amount of our revenue to Grass Book House, a non-profit organization in Taiwan that supports children in need.`,
  charityWishlist:
    'We believe in making a difference in the lives of children at Grass Book House. Our "Wish Card" program allows them to make a special wish, which UBC members collect and our students personally select and deliver the gifts, spreading kindness to those who need it most.',
};

const mentor = {
  mentorAliceTitle: 'Zalando\nSenior Product Designer',
  mentorAliceIntro01: `In 2022, made the decision to venture out of Taiwan and explore the world, currently employed as a product designer at Germany's largest e-commerce platform in Berlin.`,

  mentorAliceIntro02:
    'Acknowledged as an ADPlist Mentor, having earned a place among the top 10% contributors in Taiwan.',

  mentorAliceIntro03:
    'Specializes in various areas such as problem-solving, design strategy, career planning, portfolio organization, interview tips, and salary negotiation.',

  mentorAliceIntro04:
    'Possesses experience in designing products for education, e-commerce platforms, streaming services, social media, and more.',
  mentorAliceIntro05: 'Also serves as a Yoga instructor at Universal Yoga.',

  mentorBeccaTitle: 'Isobar\nSenior UX Designer',
  mentorBeccaIntro01:
    'Focuses on designing brand e-commerce platforms and LINE official account services.',

  mentorBeccaIntro02:
    'Transitioned into a user experience designer for the past four years with rich experience in mobile app and web design. Served as a former project manager of the UX department at Isobar Taiwan.',

  mentorBeccaIntro03:
    'Collaborated with various industry leading companies, include Volvo, Hot Pot King, ASUS, Kenmont Aveda, Amway, Chunghwa Post, Shiseido, and Cathay United Bank.',

  mentorBeckyTitle: 'BITGIN\nProduct Manager',
  mentorBeckyIntro01:
    'Served as a product manager for App and IoT products at Foxconn Group and an e-commerce platform product manager at Gamania Group.',

  mentorBeckyIntro02:
    'Possesses practical experience in software, hardware, and product integration.',

  mentorBeckyIntro03:
    'Holds PMI-PMP project management certification and international Scrum Master certification.',

  mentorEmelineTitle: 'Bytedance\nSenior UX Designer',
  mentorEmelineIntro01:
    'Serves as a member of the design team at ByteDance Feishu in Shanghai.',

  mentorEmelineIntro02:
    'Served as a UI designer at Microsoft Research Asia in Beijing and a collaborative product designer for the Game-Fi project at Taiwan Industrial Technology Research Institute.',

  mentorEmelineIntro03:
    'Specializes in end-to-end B2B product design, including internet and blockchain fields.',
  mentorGenieTitle: 'Far Eas Tone\nSenior Product Designer',
  mentorGenieIntro01:
    'Accumulates a large number of works, also introduces data into the company and establishs user experience indicators to understand user behavior through data analysis.',

  mentorGenieIntro02:
    'Introduces design processes in business-oriented environment, allowing design to play a more important role in product development.',

  mentorGenieIntro03:
    'Specializes in end-to-end B2B product design, including internet and blockchain fields.',
  mentorKatTitle: 'iCHEF\nProduct Designer',
  mentorKatIntro01:
    'Serves as a product designer in the food tech industry, striving to help restaurant owners with their operations.',
  mentorKatIntro02:
    'Has experienced design and development processes in both agency and in-house environments, I understand the design thinking and survival skills required in different contexts.',
  mentorKatIntro03:
    'Prefers to read psychology, behavioral economics, social science, and other related books rather than design methodology books.',
  mentorLynnTitle: 'UI/UX Designer',
  mentorLynnIntro01: `Proficient in conducting thorough user research to identify optimal solutions for enhancing usability and user experience.`,
  mentorLynnIntro02:
    'Experienced various business contexts, including banking, catering, e-commerce, IoT, and SaaS.',
  mentorLynnIntro03:
    'Collaborated with the team to develop the interface design and illustration system utilized by the Taishin Richart digital bank.',
  mentorLynnIntro04:
    'UI/UX lecturer and workshop facilitator who assists Google Developer Groups and Women Techmakers to empower the local technology community.',
  mentorLynnIntro05:
    'Believes designers are the vanguard of the future. Founded the "Kuusou Science" podcast.',
  mentorMarkTitle: 'KKCompany\nExperience Design Manager',
  mentorMarkIntro01:
    'Serves as the Experience Design AVP at KKCompany, responsible for the user experience design and team management of KKBOX product.',

  mentorMarkIntro02:
    'Applys cross-disciplinary perspectives to guide the advancement of product features and the demonstration of design values within the organization.',

  mentorMarkIntro03:
    'Shares career transition story and workplace experience through various channels in recent years.',

  mentorMavisTitle: 'Manager, UX Designer',

  mentorMavisIntro01:
    'Specializes in end-to-end design for e-commerce and financial industries with over 7 years of experience as a UX designer  ',

  mentorMavisIntro02:
    'Served as a user researcher at Alibaba in Hangzhou, and as a UI/UX designer at Publicis Sapient, EF Education, Cathay Financial Holdings in Taipei, and Line Business Banking in Shanghai.',

  mentorMaxTitle: 'MCU Information Management Dept.\nAssistant Professor',

  mentorMaxIntro01:
    'Instructs at the Department of Information Management at Ming Chuan University.',
  mentorMaxIntro02:
    'Serves as a board member of UXTW and IxDA Taiwan, as well as the coordinator of HPX Campus.',
  mentorMaxIntro03:
    'Serves as a Co-translator of the fourth edition of "Information Architecture."',
  mentorMaxIntro04:
    'Enjoys analyzing the interactions between information, people, and technology.',

  mentorNitaTitle: 'KKCompany\nSenior Designer',

  mentorNitaIntro01: `Developing ToB & ToC media streaming services, and building a video streaming platform for Japan's leading cable television operator.
`,

  mentorNitaIntro02:
    'Has provided digital design solutions for consumer electronics, gamified education, digital finance, and other industries.',

  mentorNitaIntro03:
    'Experiences in trend research, user research, UX design, and other design roles.',

  mentorNitaIntro04:
    'Specializes in translating research into design and enjoys analyzing the impact of trends on design decisions.',

  mentorRivenTitle: 'Digital Nomadic Designer',
  mentorRivenIntro01: 'Works as a digital nomad designer in the IT industry.',

  mentorRivenIntro02:
    'Serves as a consultant and lecturer for various colleges and community organizations.',

  mentorRivenIntro03:
    'Being invited by Adobe to represent Taiwan and attended the Adobe MAX designer conference in Los Angeles.',

  mentorRivenIntro04:
    'Specializes in writing about design knowledge and observations on Medium, with a readership of approximately 8K.',

  mentorSokingTitle: 'Chiaki Design\nProduct Design Chief',
  mentorSokingIntro01:
    'Serves as a senior product designer and UX lecturer with over a decade of experience in the software industry.',

  mentorSokingIntro02:
    'Leads Chiaki Creative Design Co., Ltd., providing consulting services related to user experience design.',

  mentorSokingIntro03:
    'Assists enterprise clients in creating digital product experiences and spreading knowledge about UX.',

  mentorWendyTitle: 'Cathay United Bank\nUI Designer',

  mentorWendyIntro01:
    'Collaborates with cross-functional teams to optimize product process experience and review design quality.',

  mentorWendyIntro02:
    'Possesses cross-platform communication and integration skills.',

  mentorWendyIntro03:
    'Strives to continue creating great designs and enjoyable user experiences.',
};

const config = {
  language: 'Language',
  off: 'off',
  on: 'on',
  darkMode: 'Dark mode',
};

export const localEn = {
  ...config,
  ...value,
  ...achievements,
  ...howWeWork,
  ...whatWillLearn,
  ...charity,
  ...mentor,
};
