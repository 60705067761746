const value = {
  valueTitle01: 'Learn',
  valueTitle02: 'Support',
  valueTitle03: 'Share',
  valueTitle04: 'Connect',
  valueContent01:
    '與 UBC 的學伴攜手完成 Google UX Design Certificate，並獲得至少 3 個經過驗證的設計專案。',
  valueContent02:
    'UBC 總收益扣除營運支出後，全額捐贈「小草書屋」，並合作舉辦公益活動，為孩童奉上心力。',
  valueContent03:
    '來自不同產業的 14 位飛行導師，帶領學員深入了解業界需求，分享專業路上的各種過程及成功經驗。',
  valueContent04:
    '透過相互交流，於 UBC 建立屬於你的人脈網，增加工作上互助的好夥伴，並提高職場即戰力。',
};

const achievements = {
  achievement01: '2 個月內 ACCUPASS 活動頁瀏覽量',
  achievement02: '讀書會學員報名數',
  achievement03: '最終錄取總人數',
};

const howWeWork = {
  howWeWorkIntro:
    '依照工作資歷均分組，由資深成員帶領初階成員一同學習，建立「導師制度」。',
  howWeWorkContent01: '由當週負責小組準備導讀簡報，帶領大家複習章節重點。',
  howWeWorkContent02: '飛行導師依據章節內容及學員課前提問，進行分享與問答。',
  howWeWorkContent03:
    '學員會前將作業貼於協作白板，學員互相給予文字回饋，並進行分組語音討論。',
  howWeWorkContent04:
    '每組兩位學員於五、六、七章節時進行發表，飛行導師依據作品給予回饋。',
};

const whatWillLearn = {
  whatWillLearnCertification01: 'Google UX Design Professional Certificate',
  whatWillLearnCertification02: 'Google UX Design 官方認證章節完成證書',
  whatWillLearnCertification03: 'UBC 讀書會區塊鏈參與證書',
  whatWillLearn01Experience01: '至少 3 個經過驗證的設計專案',
  whatWillLearn01Experience02: '知識輸入、輸出與簡報能力',
  whatWillLearn01Experience03: '遠端團隊溝通與管理能力',
};

const charity = {
  charityDonation:
    '第二屆 UBC 聯合讀書會的收益扣除營運支出後，將全額捐贈給「小草書屋」。',
  charityWishlist:
    '「小草書屋」孩子們將於「心願卡」上許下禮物，我們將召集 UBC 學員認領心願卡，並將孩子的禮物寄送到孩子們的手中。',
};

const mentor = {
  mentorAliceTitle: 'Zalando 資深產品設計師',
  mentorAliceIntro01:
    '於2022年決定離開台灣，目前在德國柏林擔任德國最大電商平臺的資深產品設計師',
  mentorAliceIntro02:
    '致力於 ADPlist 提供職涯諮詢，並已於 2023 年協助超過 100 名學員',
  mentorAliceIntro03:
    '擅長解決流程問題、設計策略、職業規劃、作品集準備、面試技巧和薪資談判等領域',
  mentorAliceIntro04:
    '擁有在教育、電商平台、影音串流、社交媒體等領域設計產品的經驗',
  mentorAliceIntro05: 'Universal Yoga 寰宇瑜伽老師',
  mentorBeccaTitle: 'Isobar 資深 UX 設計師',
  mentorBeccaIntro01: '近期專注品牌電商平台與 LINE 官方帳號服務設計',
  mentorBeccaIntro02:
    '曾擔任 Isobar Taiwan UX 部門專案經理，近四年轉為使用者體驗設計師，有豐富 mobile APP 與 Web 經驗',
  mentorBeccaIntro03:
    '曾服務過的品牌有：Volvo、石二鍋、華碩、肯夢 Aveda、安麗、中興保全、資生堂、國泰世華銀行等',
  mentorBeckyTitle: 'BITGIN 產品經理',
  mentorBeckyIntro01:
    '曾任鴻海集團 App、IoT 產品經理、遊戲橘子集團電商平台產品經理等',
  mentorBeckyIntro02: '具備軟體、硬體、軟硬整合等產品實務工作經驗',
  mentorBeckyIntro03:
    '擁有 PMI-PMP 專案管理資格認證，以及國際 Scrum Master 認證',
  mentorEmelineTitle: 'Bytedance 資深 UX 設計師',
  mentorEmelineIntro01: '現任上海字節跳動飛書設計團隊',
  mentorEmelineIntro02:
    '曾擔任北京微軟亞洲研究院 UI 設計師、台灣工研院區塊鏈 Game-Fi 專案合作產品設計師',
  mentorEmelineIntro03: '擅長 B 端產品全流程設計，包括互聯網、區塊鏈等領域',
  mentorGenieTitle: '遠傳電信 資深產品設計師',
  mentorGenieIntro01:
    '在累積大量作品時也將 Data 引入公司，並設立使用者體驗指標，使用數據了解使用者的行為。',
  mentorGenieIntro02:
    '同時在極商業的環境中，導入設計流程，讓設計在產品發展上扮演更重要的角色。',
  mentorKatTitle: 'iCHEF 產品設計師',
  mentorKatIntro01:
    '現為餐飲科技業擔任產品設計師，為了幫助餐飲老闆們的營運而努力',
  mentorKatIntro02:
    '曾經歷 Agency 和 Inhouse 的設計與開發流程，了解不同環境的設計思維和生存技巧',
  mentorKatIntro03:
    '比起設計方法論書籍，更愛閱讀心理學、行為經濟學、社會科學等書類',
  mentorLynnTitle: 'UI 設計師',
  mentorLynnIntro01:
    '擅長從設計思考到執行，透過使用者研究，探索易用性與體驗的最佳解。',
  mentorLynnIntro02:
    '在 AJA 大予創意任職時期，累積含金融、餐飲、電商、IoT、SaaS 等多種產品經驗。',
  mentorLynnIntro03:
    '曾與團隊一同打造台新 Richart 數位銀行的介面設計與插畫系統。',
  mentorLynnIntro04:
    'UIUX 講師與工作坊主持人，協助 Google Developer Groups 與 Women Techmakers 做技術社區的支援。',
  mentorLynnIntro05:
    '認為設計師是「第一批接觸未來的人」，創立 ⟪空想科研⟫ Podcast 節目 。',
  mentorMarkTitle: 'KKCompany 體驗設計經理',
  mentorMarkIntro01:
    '目前為 KKCompany 的 Experience Design AVP 負責 KKBOX 產品的體驗設計與團隊管理',
  mentorMarkIntro02:
    '運用跨領域的觀點來引導產品功能的推進以及設計價值在組織中的展現',
  mentorMarkIntro03: '近年來透過不同方式分享自己的轉職故事以及職場經驗',
  mentorMavisTitle: '經理、用户體驗設計師',
  mentorMavisIntro01: '超過 7 年設計體驗，擅長電商、金融產業的全鏈路設計師',
  mentorMavisIntro02:
    '過去曾擔任杭州阿里巴巴用戶研究員、上海 Publicis sapient、EF Education、台北國泰金融控股及連線商業銀行的 UI/UX Designer',
  mentorMaxTitle: '銘傳大學資管系 助理教授',
  mentorMaxIntro01: '目前任教於銘傳大學資管系',
  mentorMaxIntro02: '擔任 UXTW 與 IxDA Taiwan 理事及 HPX Campus 召集人',
  mentorMaxIntro03: '為《資訊架構學》第四版共同譯者',
  mentorMaxIntro04: '喜好解析資訊、人、科技，與三者間的互動',
  mentorNitaTitle: 'KKCompany 資深產品設計師',
  mentorNitaIntro01:
    '目前在 KKCompany 打造ToB to C的串流媒體服務，為日本有線電視龍頭建立線上影音平台',
  mentorNitaIntro02: '曾為消費電子、遊戲化教育、數位金融等行業提供數位設計方案',
  mentorNitaIntro03: '經歷趨勢研究、用戶研究、UX 設計等設計角色轉換',
  mentorNitaIntro04: '擅長轉化研究到設計，喜好解析趨勢對設計決策的影響',
  mentorRivenTitle: '數位遊牧型態 設計師',
  mentorRivenIntro01: '目前為 IT 產業工作的數位遊牧型態設計師',
  mentorRivenIntro02: '多間大專院校與社群機構擔任顧問及講師',
  mentorRivenIntro03:
    '曾獲 Adobe 邀請代表台灣飛往洛杉磯參加 Adobe MAX 設計師年會',
  mentorRivenIntro04:
    '擅長 Medium 寫作設計上的知識與觀察，追蹤讀者人數已達已達約 8K',
  mentorSokingTitle: '千綺創意設計 產品設計總監',
  mentorSokingIntro01: '資深產品設計師與 UX 講師，軟體產業經驗十多年資歷',
  mentorSokingIntro02:
    '千綺創意設計股份有限公司負責人，提供用戶體驗設計相關顧問服務',
  mentorSokingIntro03: '協助企業客戶打造數位產品體驗與 UX 知識的傳教',
  mentorWendyTitle: '國泰世華銀行 UI 設計師',
  mentorWendyIntro01: '現與跨團隊一同致力於優化產品流程體驗和檢視設計品質',
  mentorWendyIntro02: '擁有跨平台溝通與整合能力',
  mentorWendyIntro03: '期許能夠持續創造良好的設計與愉悅的使用體驗',
};

const config = {
  language: '語言',
  off: '關閉',
  on: '開啟',
  darkMode: '深夜模式',
};

export const localZh = {
  ...config,
  ...value,
  ...achievements,
  ...howWeWork,
  ...whatWillLearn,
  ...charity,
  ...mentor,
};
