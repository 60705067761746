import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import i18n from 'i18next';

import {
  Flex,
  Text,
  HStack,
  VStack,
  Icon,
  useToast,
  Link,
  Image,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react';

import { FaFacebookF, FaLinkedinIn, FaEnvelope } from 'react-icons/fa';
import portaly from 'assets/images/portaly_logo.png';

const Footer = () => {
  const { t } = useTranslation();
  const currentLanguage = i18n.language;
  const [searchParams, setSearchParams] = useSearchParams();
  const { toggleColorMode } = useColorMode();
  const text = useColorModeValue('on', 'off');
  const toast = useToast({
    // use own variant
    variant: 'toast',
  });
  const isZhTW = currentLanguage === 'zh-TW';

  const params = [];

  searchParams.forEach((value, key) => {
    params.push([key, value]);
  });

  useEffect(() => {
    if (params[0]) {
      setSearchParams({ local: params[0] ? params[0][1] : '' });
      i18n.changeLanguage(params[0][1]);
      localStorage.setItem('language', params[0][1]);
      console.log('hu', params[0][1]);
    }
  }, []);

  const handleChangeLanguage = lngId => {
    i18n.changeLanguage(lngId);
    localStorage.setItem('language', lngId);
    setSearchParams({ local: lngId });
  };

  const handleClick = () => {
    navigator.clipboard.writeText('pickupdesigntw@gmail.com');
    toast({
      title: 'pickupdesigntw@gmail.com',
      description: 'Copied to clipboard',
      status: 'success',
      duration: 1000,
      isClosable: true,
      containerStyle: {
        bgColor: 'brand-color',
        color: 'white',
        rounded: 'radii-button',
      },
    });
  };

  return (
    <Flex
      w="100%"
      bgColor="grey.600"
      h={{ base: '250px', lg: '350px' }}
      align="center"
      justify="center"
    >
      <VStack spacing="20px">
        <HStack spacing="20px">
          <Flex
            as={Link}
            isExternal
            href="https://www.facebook.com/ubctaiwan/"
            w="50px"
            h="50px"
            rounded="full"
            bgColor="gray.100"
            color="gray.900"
            align="center"
            justify="center"
            cursor="pointer"
          >
            <Icon w={5} h={5} as={FaFacebookF} />
          </Flex>
          <Flex
            as={Link}
            isExternal
            href="https://www.linkedin.com/company/ux-book-club/"
            w="50px"
            h="50px"
            rounded="full"
            bgColor="gray.100"
            color="gray.900"
            align="center"
            justify="center"
            cursor="pointer"
          >
            <Icon w={5} h={5} as={FaLinkedinIn} />
          </Flex>
          <Flex
            w="50px"
            h="50px"
            rounded="full"
            bgColor="gray.100"
            color="gray.900"
            align="center"
            justify="center"
            cursor="pointer"
            onClick={() => handleClick()}
          >
            <Icon w={5} h={5} as={FaEnvelope} />
          </Flex>
          <Flex
            as={Link}
            isExternal
            href="https://portaly.cc/PickupDesign"
            w="50px"
            h="50px"
            rounded="full"
            bgColor="gray.100"
            color="gray.900"
            align="center"
            justify="center"
            cursor="pointer"
          >
            <Image src={portaly} alt="portaly" />
          </Flex>
        </HStack>
        <Flex w="100%" maxW="500px" justify="space-between">
          <VStack align="flex-start" spacing="2px">
            <Text color="white" fontSize="16px" fontWeight="600" opacity=".7">
              {t('language')}
            </Text>
            <HStack spacing="16px">
              <Text
                color={isZhTW ? 'whiteAlpha.900' : 'whiteAlpha.500'}
                cursor="pointer"
                onClick={() => handleChangeLanguage('zh-TW')}
              >
                繁體中文
              </Text>
              <Text
                color={!isZhTW ? 'whiteAlpha.900' : 'whiteAlpha.500'}
                cursor="pointer"
                onClick={() => handleChangeLanguage('en')}
              >
                English
              </Text>
            </HStack>
          </VStack>
          <VStack align="flex-start" spacing="2px">
            <Text color="white" fontSize="16px" fontWeight="600" opacity=".7">
              {t('darkMode')}
            </Text>
            <Text
              color="whiteAlpha.900"
              onClick={toggleColorMode}
              cursor="pointer"
            >
              {t(text)}
            </Text>
          </VStack>
        </Flex>
        <Text textStyle="content03" color="whiteAlpha.800">
          Copyright UX Book Club Taiwan 2023
        </Text>
      </VStack>
    </Flex>
  );
};

export default Footer;
