import { useState, useLayoutEffect, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Text,
  Flex,
  VStack,
  HStack,
  Grid,
  GridItem,
  Modal,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  Stack,
} from '@chakra-ui/react';

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import {
  TwinkleIcon,
  DotIcon,
  SpiralIcon,
  CloseIcon,
  ArrowRightIcon,
  LightIcon,
} from 'components/icons';
import MentorImage from 'components/MentorImage';

gsap.registerPlugin(ScrollTrigger);

const MENTOR_INTRO_LIST = [
  {
    id: 'Alice',
    name: 'Alice Wu',
    nameCn: '吳敏瑜',
    title: 'mentorAliceTitle',
    intro: [
      'mentorAliceIntro01',
      'mentorAliceIntro02',
      'mentorAliceIntro03',
      'mentorAliceIntro04',
      'mentorAliceIntro05',
    ],
  },
  {
    id: 'Becca',
    name: 'Becca Lin',
    nameCn: '林宥廷',
    title: 'mentorBeccaTitle',
    intro: ['mentorBeccaIntro01', 'mentorBeccaIntro02', 'mentorBeccaIntro03'],
  },
  {
    id: 'Becky',
    name: 'Becky Shih',
    nameCn: '施雅絨',
    title: 'mentorBeckyTitle',
    intro: ['mentorBeckyIntro01', 'mentorBeckyIntro02', 'mentorBeckyIntro03'],
  },
  {
    id: 'Emeline',
    name: 'Emeline Lu',
    nameCn: '盧琳璐',
    title: 'mentorEmelineTitle',
    intro: [
      'mentorEmelineIntro01',
      'mentorEmelineIntro02',
      'mentorEmelineIntro03',
    ],
  },
  {
    id: 'Genie',
    name: 'Genie Lu',
    nameCn: '盧仙萍',
    title: 'mentorGenieTitle',
    intro: ['mentorGenieIntro01', 'mentorGenieIntro02'],
  },
  {
    id: 'Kat',
    name: 'Kat Wang',
    nameCn: '王蓁妮',
    title: 'mentorKatTitle',
    intro: ['mentorKatIntro01', 'mentorKatIntro02', 'mentorKatIntro03'],
  },
  {
    id: 'Lynn',
    name: 'Lynn Huang',
    nameCn: '黃翎',
    title: 'mentorLynnTitle',
    intro: [
      'mentorLynnIntro01',
      'mentorLynnIntro02',
      'mentorLynnIntro03',
      'mentorLynnIntro04',
      'mentorLynnIntro05',
    ],
  },
  {
    id: 'Mark',
    name: 'Mark Liang',
    nameCn: '梁祐文',
    title: 'mentorMarkTitle',
    intro: ['mentorMarkIntro01', 'mentorMarkIntro02', 'mentorMarkIntro03'],
  },
  {
    id: 'Mavis',
    name: 'Mavis Kao',
    nameCn: '高琬齊',
    title: 'mentorMavisTitle',
    intro: ['mentorMavisIntro01', 'mentorMavisIntro02'],
  },
  {
    id: 'Max',
    name: 'Max Chen',
    nameCn: '陳書儀',
    title: 'mentorMaxTitle',
    intro: [
      'mentorMaxIntro01',
      'mentorMaxIntro02',
      'mentorMaxIntro03',
      'mentorMaxIntro04',
    ],
  },
  {
    id: 'Nita',
    name: 'Nita Wu',
    nameCn: '吳芳葆',
    title: 'mentorNitaTitle',
    intro: [
      'mentorNitaIntro01',
      'mentorNitaIntro02',
      'mentorNitaIntro03',
      'mentorNitaIntro04',
    ],
  },
  {
    id: 'Riven',
    name: 'Riven Lin',
    nameCn: '林育正',
    title: 'mentorRivenTitle',
    intro: [
      'mentorRivenIntro01',
      'mentorRivenIntro02',
      'mentorRivenIntro03',
      'mentorRivenIntro04',
    ],
  },
  {
    id: 'Soking',
    name: 'Soking Wang',
    nameCn: '王彥博',
    title: 'mentorSokingTitle',
    intro: [
      'mentorSokingIntro01',
      'mentorSokingIntro02',
      'mentorSokingIntro03',
    ],
  },
  {
    id: 'Wendy',
    name: 'Wendy Chen',
    nameCn: '陳文憶',
    title: 'mentorWendyTitle',
    intro: ['mentorWendyIntro01', 'mentorWendyIntro02', 'mentorWendyIntro03'],
  },
];

const MentorCard = props => {
  const { name, title, id, handleClick, data } = props;
  const { t } = useTranslation();
  return (
    <GridItem
      border="4px solid #EA593D"
      _hover={{ border: '4px solid ' }}
      role="group"
      as={Flex}
      onClick={() => handleClick(data)}
      cursor="pointer"
      bgColor="brand-color"
      rounded="cardMd"
      px="24px"
      pt="10px"
      pb="30px"
      transition="all .5s"
      position="relative"
    >
      <LightIcon
        _groupHover={{ opacity: '1' }}
        transition="all .5s"
        opacity="0"
        position="absolute"
        top="16px"
        left="16px"
      />
      <VStack w={{ base: '180px', lg: '340px' }} spacing="16px">
        <MentorImage salon={id} />
        <VStack spacing="4px">
          <Text color="white" textStyle="content01">
            {name}
          </Text>
          <Text color="white" textStyle="content03" whiteSpace="pre-wrap">
            {t(title)}
          </Text>
        </VStack>
      </VStack>
    </GridItem>
  );
};

const List = props => {
  const { content } = props;
  const { t } = useTranslation();
  return (
    <Flex
      maxH="360px"
      overflowY="scroll"
      css={{
        '&::-webkit-scrollbar': {
          width: '4px',
        },
        '&::-webkit-scrollbar-track': {
          width: '6px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: ' #EA593D99',
          borderRadius: '24px',
        },
      }}
      position="relative"
    >
      <VStack
        spacing="8px"
        // justify="flex-end"
        align="flex-start"
        pt={{ base: '20px', lg: 'unset' }}
      >
        {content?.map(item => (
          <HStack key={item} align="flex-start" spacing="12px">
            <DotIcon
              w={{ base: '20px', lg: '30px' }}
              h={{ base: '20px', lg: '30px' }}
              fontSize="30px"
            />
            <Text textStyle="content02">{t(item)}</Text>
          </HStack>
        ))}
      </VStack>
    </Flex>
  );
};

const MentorModal = props => {
  const { isOpen, onClose, data } = props;
  const { name, nameCn, id, title, intro } = data;
  const { t } = useTranslation();
  return (
    <Modal
      blockScrollOnMount={false}
      w="1200px"
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        w="80%"
        maxW="1350px"
        h={{ base: '550px', lg: '750px' }}
        bgColor="bg-default"
        rounded={{ base: 'cardMd', lg: 'cardLg' }}
        overflow="hidden"
      >
        <Flex
          w="100%"
          h="100%"
          direction={{ base: 'column-reverse', lg: 'row' }}
        >
          {/* 文字簡介 */}
          <Flex
            maxH={{ base: '318px', lg: 'unset' }}
            overflowY={{ base: 'scroll', lg: 'unset' }}
            flex="1"
            w={{ base: '100%', lg: '50%' }}
            p="100px"
            px={{ base: '16px', lg: '60px', xl: '80px' }}
            pt={{ base: '20px', lg: '100px' }}
            pb={{ base: '40px', lg: '100px' }}
            direction="column"
            justify={{ base: ' flex-start', lg: 'space-between' }}
          >
            <VStack
              align={{ base: 'center', lg: 'flex-start' }}
              spacing={{ base: '4px', lg: '20px' }}
            >
              <Stack
                direction={{ base: 'row', lg: 'column' }}
                spacing={{ base: '4px', lg: '0' }}
              >
                <HStack spacing="30px">
                  <Text
                    textStyle="heading02"
                    fontSize={{ base: '24px', lg: '40px', xl: '56px' }}
                    color="brand-color"
                  >
                    {name}
                  </Text>
                  <ArrowRightIcon display={{ base: 'none', lg: 'flex' }} />
                </HStack>
                <Text textStyle="heading04" fontWeight="700">
                  {nameCn}
                </Text>
              </Stack>
              <Text
                textStyle="content02"
                color="brand-color"
                textAlign={{ base: 'center', lg: 'left' }}
              >
                {t(title)}
              </Text>
            </VStack>
            <List content={intro} />
          </Flex>
          {/* 導師照片 */}
          <Flex
            flex={{ base: '1', lg: 'unset' }}
            w={{ base: '100%', lg: '50%' }}
            h={{ base: '240px', lg: '100%' }}
            bgColor="brand-color"
            px="20px"
            align="center"
            justify="center"
            position="relative"
          >
            <Flex
              onClick={onClose}
              p={{ base: '2px', lg: '12px' }}
              right={{ base: '20px', lg: '30px' }}
              top={{ base: '20px', lg: '30px' }}
              position="absolute"
              cursor="pointer"
            >
              <CloseIcon w="24px" h="24px" />
            </Flex>
            <Flex
              position="absolute"
              top={{ base: '32px', lg: '40px' }}
              left={{ base: '32px', lg: '40px' }}
              color="white"
            >
              <TwinkleIcon
                w={{ base: '48px', lg: '120px' }}
                h={{ base: '48px', lg: '120px' }}
              />
            </Flex>
            <Flex
              position="absolute"
              bottom={{ base: '10px', lg: '20px' }}
              right={{ base: '10px', lg: '20px' }}
              zIndex="1"
              transform="rotateX(180deg) rotate(90deg)"
              color="white"
            >
              <SpiralIcon
                w={{ base: '70px', lg: '180px' }}
                h={{ base: '70px', lg: '180px' }}
              />
            </Flex>
            <Flex w={{ base: '150px', lg: '450px' }}>
              <MentorImage dome={id} />
            </Flex>
          </Flex>
        </Flex>
      </ModalContent>
    </Modal>
  );
};

const MentorSection = () => {
  const [bodyY, setBodyY] = useState({});
  const [modalData, setModalData] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure();
  const videoPartRef = useRef(null);

  const handleClick = data => {
    onOpen();
    setModalData(data);
  };

  const currentWidth = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  );

  useEffect(() => {
    const observer = new ResizeObserver(([entry]) => {
      setBodyY(entry.contentRect.height); // body 元素高度變化時執行此 callback 函式
    });

    observer.observe(document.body);
    let ctx = gsap.context(() => {
      gsap.to('.video-part', {
        x: -(videoPartRef.current.scrollWidth - currentWidth),
        //this did not detect the window's width change automatically
        scrollTrigger: {
          id: 'video-part',
          start: '60% 60%',
          end: `+=${videoPartRef.current.scrollWidth - currentWidth - 400}`,
          trigger: '.section-two',
          scrub: 1,
          // markers: true,
          pin: true,
        },
      });
    });
    return () => {
      observer.unobserve(document.body);

      ctx.revert();
      ctx.kill();
    };
  }, [bodyY]);

  return (
    <Flex
      w="100%"
      justify="center"
      py={{ base: '80px', lg: '150px' }}
      className="section-two"
      overflow="hidden"
    >
      <MentorModal data={modalData} isOpen={isOpen} onClose={onClose} />
      <Flex
        w="100%"
        p={{ base: '24px', lg: '0' }}
        direction="column"
        align="center"
        className="section-two"
      >
        <VStack
          spacing={{ base: '4px', lg: '24px' }}
          pb={{ base: '40px', lg: '80px' }}
          position="relative"
        >
          <Flex
            position="absolute"
            left={{ base: '-50px', lg: '-150px' }}
            top={{ base: '-5px', lg: '-30px' }}
          >
            <TwinkleIcon
              w={{ base: '45px', lg: '103px' }}
              h={{ base: '45px', lg: '103px' }}
            />
          </Flex>
          <Text textStyle="heading01">Mentors</Text>
          <Text textStyle="content01">Click to learn more!</Text>
        </VStack>

        <Flex w="100%" justify="flex-start">
          <Flex
            ref={videoPartRef}
            className="video-part"
            px={{ base: '0', lg: '100px' }}
          >
            <Grid
              pr={{ baser: '40px', lg: 'unset' }}
              templateColumns={{
                base: 'repeat(7, 1fr)',
                lg: 'repeat(14, 1fr)',
              }}
              gap={{ base: '24px', lg: '50px' }}
              align="center"
            >
              {MENTOR_INTRO_LIST.map(item => (
                <MentorCard
                  key={item.id}
                  {...item}
                  data={item}
                  handleClick={handleClick}
                />
              ))}
            </Grid>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default MentorSection;
