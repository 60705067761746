import CollapseIconMotion from 'components/CollapseIconMotion';

import {
  Text,
  Flex,
  VStack,
  HStack,
  SimpleGrid,
  Collapse,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react';

import MentorImage from 'components/MentorImage';

const TIME_TABLE_DATA = [
  {
    date: 'Feb. 19',
    order: '1',
    course: 'Foundations of \n User Experience (UX) Design',
    mentors: ['Becca', 'Becky', 'Max', 'Nita'],
  },
  {
    date: 'Mar. 05',
    order: '2',
    course: 'Start the UX Design Process : \n Empathize,Define, and Ideate',
    mentors: ['Becca', 'Becky', 'Mavis', 'Max'],
  },
  {
    date: 'Mar. 19',
    order: '3',
    course: 'Build Wireframes and \n Low-Fidelity Prototypes',
    mentors: ['Becca', 'Soking', 'Mavis', 'Max'],
  },
  {
    date: 'Apr. 02',
    order: '4',
    course: 'Conduct UX Research and \n Test Early Concepts',
    mentors: ['Alice', 'Genie', 'Nita', 'Soking'],
  },
  {
    date: 'Apr. 16',
    order: '5',
    course: 'Create High-Fidelity Designs and  and \n Prototypes in Figma',
    mentors: ['Kat', 'Mark', 'Nita', 'Wendy'],
  },
  {
    date: 'Apr. 30',
    order: '6-1',
    course: 'Responsive Web Design \n in Adobe XD',
    mentors: ['Alice', 'Riven', 'Max', 'Wendy'],
  },
  {
    date: 'May. 14',
    order: '6-2',
    course: 'Responsive Web Design \n in Adobe XD',
    mentors: ['Alice', 'Emeline', 'Riven', 'Wendy'],
  },
  {
    date: 'May. 28',
    order: '7-1',
    course: 'Design a User Experience for \n Social Good & Prepare for Jobs',
    mentors: ['Becca', 'Emeline', 'Genie', 'Nita'],
  },
  {
    date: 'Jun. 18',
    order: '7-2',
    course: 'Design a User Experience for \n Social Good & Prepare for Jobs',
    mentors: ['Alice', 'Kat', 'Lynn', 'Mark'],
  },
];

const TimeCard = props => {
  const { date, course, order, mentors } = props;
  const { isOpen, onToggle } = useDisclosure();
  const [isLargerThan800] = useMediaQuery('(min-width: 800px)');

  return (
    <Flex
      p="16px 20px"
      rounded="cardMd"
      bgColor="bg-default"
      w="100%"
      direction="column"
    >
      <Flex
        justify="space-between"
        align="center"
        onClick={isLargerThan800 ? undefined : onToggle}
      >
        <HStack spacing="10px">
          <Text textStyle="text01" fontWeight="700" color="brand-color">
            {date}
          </Text>
          <Text textStyle="text01">COURSE {order}</Text>
        </HStack>
        <Flex onClick={onToggle} display={{ base: 'flex', md: 'none' }}>
          <CollapseIconMotion isActive={isOpen} />
        </Flex>
      </Flex>

      <Collapse
        in={isOpen}
        animateOpacity
        display={{ base: 'flex', md: 'none' }}
      >
        <Text textStyle="text02" whiteSpace="pre-wrap">
          {course}
        </Text>
        <HStack pt="20px" spacing="12px">
          {mentors.map(item => (
            <VStack key={item}>
              <MentorImage avatar={item} />
              <Text fontWeight="500" textStyle="content03" key={item}>
                {item}
              </Text>
            </VStack>
          ))}
        </HStack>
      </Collapse>
      <Flex display={{ base: 'none', md: 'flex' }} direction="column">
        <Text textStyle="text02" whiteSpace="pre-wrap">
          {course}
        </Text>
        <HStack pt="20px" spacing="12px">
          {mentors.map(item => (
            <VStack key={item}>
              <MentorImage avatar={item} />
              <Text fontWeight="500" textStyle="content03" key={item}>
                {item}
              </Text>
            </VStack>
          ))}
        </HStack>
      </Flex>
    </Flex>
  );
};

const TimeFlag = props => {
  const { date, message } = props;
  return (
    <Flex
      bgColor="bg-default"
      h="62px"
      w="100%"
      px="16px"
      justify={{ base: 'flex-start', lg: 'center' }}
      rounded="cardMd"
    >
      <HStack>
        <Text textStyle="text01" fontWeight="700" color="brand-color">
          {date}
        </Text>
        <Text textStyle="text01">{message}</Text>
      </HStack>
    </Flex>
  );
};

const TimetableSection = () => {
  return (
    <Flex bgColor="bg-secondary" w="100%" justify="center">
      <Flex
        w={{ base: '100%', md: '90%', lg: '90%' }}
        px={{ base: '24px', lg: '0' }}
        maxW="1060px"
        justify="center"
        direction="column"
        align="center"
      >
        <Text pb={{ base: '40px', lg: '80px' }} textStyle="heading01">
          Timetable
        </Text>
        <VStack w="100%" spacing={{ base: '16px', md: '20px', xl: '40px' }}>
          <TimeFlag date="Feb. 05" message="OPENING 🎉" />
          <SimpleGrid
            w="100%"
            columns={{ base: 1, md: 2, xl: 3 }}
            spacing={{ base: '16px', md: '20px', xl: '40px' }}
          >
            {TIME_TABLE_DATA.map((item, index) => (
              <TimeCard key={item.order} {...item} />
            ))}
          </SimpleGrid>
          <TimeFlag date="Jul. 02" message="CLOSING 🎓" />
        </VStack>
      </Flex>
    </Flex>
  );
};

export default TimetableSection;
