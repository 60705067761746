import { motion } from 'framer-motion';

const WorkArrowMotion = props => {
  const { delay, isOdd } = props;
  const variants = {
    visible: custom => ({
      opacity: 1,
      pathLength: 1,
      transition: {
        delay: custom * 0.5,
        duration: 0.5,
        pathLength: { type: 'tween', duration: 0.5, bounce: 0 },
      },
    }),
    hidden: custom => ({ opacity: 0, pathLength: 0 }),
  };

  const variants2 = {
    visible: custom => ({
      opacity: 1,
      transition: {
        delay: custom * 0.5 + 0.5,
        duration: 1,
        type: 'tween',
      },
    }),
    hidden: custom => ({ opacity: 0 }),
  };

  if (isOdd) {
    return (
      <motion.svg
        custom={delay}
        width="30"
        height="60"
        viewBox="0 0 30 60"
        initial="hidden"
        whileInView="visible"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_2341_16889)">
          <motion.path
            variants={variants}
            custom={delay}
            d="M23.0002 2.99999C31.487 22.2892 22.4291 45.4646 2.76894 54.7638"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <motion.path
            variants={variants2}
            custom={delay}
            d="M5 47L2 55.5L10 58.5"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_2341_16889">
            <rect width="30" height="60" fill="white" />
          </clipPath>
        </defs>
      </motion.svg>
    );
  } else {
    return (
      <motion.svg
        custom={delay}
        viewport={{ once: false, amount: 0.6 }}
        width="30"
        height="60"
        viewBox="0 0 30 60"
        initial="hidden"
        whileInView="visible"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_2341_16891)">
          <motion.path
            variants={variants}
            custom={delay}
            d="M7.46852 2.99999C-1.01823 22.2892 8.03963 45.4646 27.6998 54.7638"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <motion.path
            variants={variants2}
            custom={delay}
            d="M25.5 47L28 55L20 58"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_2341_16891">
            <rect width="30" height="60" fill="currentColor" />
          </clipPath>
        </defs>
      </motion.svg>
    );
  }
};

export default WorkArrowMotion;
