import {
  Flex,
  Text,
  Slide,
  Button,
  HStack,
  useDisclosure,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import { CloseIcon } from 'components/icons';

const TopStickyCallToAction = () => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Slide
      position="relative"
      direction="bottom"
      in={!isOpen}
      style={{ zIndex: 100 }}
    >
      <Flex
        w="100%"
        bgColor="brand-color"
        h="56px"
        justify="center"
        align="center"
        px="20px"
      >
        <Flex w="80%" maxW="1200px" justify="space-between">
          <HStack>
            <Text color="white">🎓 讀書會結業式活動報名</Text>
            <Button
              as={Link}
              to="/activity"
              variant="outline"
              size="auto"
              fontSize="12px"
              onClick={onToggle}
              padding="4px 5px"
            >
              我要參加
            </Button>
          </HStack>
          <CloseIcon />
        </Flex>
      </Flex>
    </Slide>
  );
};

export default TopStickyCallToAction;
