import { Flex } from '@chakra-ui/react';

export function Container(props) {
  const { bgColor, ...rest } = props;
  return (
    <Flex
      w={{ base: '100%', lg: '80%' }}
      maxW="1200px"
      direction="column"
      align="center"
      px={{ base: '24px', lg: 0 }}
      {...rest}
    >
      {props.children}
    </Flex>
  );
}
