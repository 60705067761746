import { motion } from 'framer-motion';

const HowWeWorkMotion = () => {
  const variants = {
    visible: {
      opacity: 1,
      pathLength: 1,
      transition: {
        pathLength: { type: 'tween', duration: 1, bounce: 0 },
        scale: { duration: 0.5 },
      },
    },
    hidden: {
      opacity: 0.75,
      pathLength: 0,
    },
  };
  const ballVariants = {
    visible: {
      opacity: 1,
      transition: {
        // pathLength: { type: 'tween', duration: 2, bounce: 0, delay: 1 },
        // scale: { duration: 0.5 },
        delay: 1,
        duration: 0.25,
      },
    },
    hidden: {
      opacity: 0,
    },
  };
  return (
    <motion.svg
      width="250"
      height="200"
      viewBox="0 0 250 200"
      initial="hidden"
      whileInView="visible"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.path
        variants={variants}
        d="M9 13H235L9 178H235"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <motion.path
        variants={ballVariants}
        d="M223 166L235 178L223 190"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinecap="round"
      />
    </motion.svg>
  );
};

export default HowWeWorkMotion;
