import { useState } from 'react';

import { motion, useMotionValueEvent, useScroll } from 'framer-motion';

import { Flex, useColorModeValue } from '@chakra-ui/react';
import { FlowIcon, GearIcon } from 'components/icons';

import charityImage from 'assets/images/charityImage.png';
import charityImageDark from 'assets/images/charityImageDark.png';

const MotionFLex = motion(Flex);

const CharityMotion = () => {
  const [degree, setDegree] = useState(0);
  const { scrollY } = useScroll();
  useMotionValueEvent(scrollY, 'change', latest => {
    setDegree((latest / 360) * 75);
  });
  const bg = useColorModeValue(charityImage, charityImageDark);

  return (
    <Flex
      bgImage={bg}
      bgRepeat="no-repeat"
      w={{ base: '320px', lg: '662px' }}
      h={{ base: '320px', lg: '600px' }}
      bgSize="contain"
      position="relative"
    >
      <MotionFLex
        bottom="30px"
        left="40px"
        position="absolute"
        w={{ base: '40px', lg: '80px' }}
        h={{ base: '40px', lg: '80px' }}
        fontSize={{ base: '40px', lg: '80px' }}
        color="item-bg"
        animate={{ rotate: degree }}
        transition={{
          type: 'spring',
          // damping: 3,
          stiffness: 50,
          restDelta: 0.001,
        }}
      >
        <FlowIcon />
      </MotionFLex>
      <MotionFLex
        position="absolute"
        top="0"
        right={{ base: '20px', lg: '120px' }}
        w={{ base: '65px', lg: '120px' }}
        h={{ base: '65px', lg: '120px' }}
        fontSize={{ base: '65px', lg: '120px' }}
        color="item-bg"
        animate={{ rotate: degree + 20 }}
        transition={{
          type: 'spring',
          // damping: 3,
          stiffness: 50,
          restDelta: 0.001,
        }}
      >
        <GearIcon />
      </MotionFLex>
    </Flex>
  );
};

export default CharityMotion;
