import { useLayoutEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

import { HomePage, ActivityPage } from 'pages';
const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo({ top: 0, behavior: 'auto' });
  }, [location.pathname]);
  return children;
};

const Router = () => {
  const location = useLocation();
  return (
    <AnimatePresence>
      <Wrapper>
        <Routes location={location} key={location.pathname}>
          <Route path="/activity" element={<ActivityPage />} />
          <Route path="/" element={<HomePage />} />
        </Routes>
      </Wrapper>
    </AnimatePresence>
  );
};

export default Router;
