import { Icon } from '@chakra-ui/react';

export function ShareIcon(props) {
  return (
    <Icon viewBox="0 0 120 120" fill="none" {...props}>
      <path
        d="M69.6173 59.9999C70.6555 58.2951 71.7286 56.5323 72.7884 54.7147C76.3359 48.6333 76.9869 42.5056 74.7239 36.5016C70.1577 24.3875 54.8938 17.0065 51.3601 15.8288C49.8339 15.3197 48.1511 15.7047 46.998 16.8267L45.8476 17.946C44.6755 19.0864 44.2113 20.8867 45.1171 22.2483C45.6985 23.1222 46.8687 24.2869 46.8687 24.2869C47.5332 25.0671 48.9684 26.1201 49.1069 28.8737C49.2253 31.2243 47.9366 33.7644 45.2778 36.4241C43.4314 38.2703 41.4049 40.0556 39.2593 41.9454C30.2319 49.8955 20 58.9089 20 75.9602C20 76.1944 20.0022 76.4272 20.0066 76.6586C20.072 80.4214 20.8802 84.1341 22.3846 87.5836C23.889 91.0331 26.0601 94.1515 28.7732 96.7595C34.2211 102.058 41.5257 105.016 49.1256 105H66.51L64.3641 100.645C55.6449 82.9486 61.811 72.8213 69.6173 59.9999ZM49.1256 98.9596C36.63 98.9596 26.2767 88.905 26.0457 76.5467C26.0421 76.3521 26.0403 76.1566 26.0402 75.9602C26.0402 61.6376 34.7902 53.9297 43.2521 46.4762C45.3735 44.6075 47.5674 42.675 49.5488 40.6932C53.4662 36.7759 55.3472 32.6965 55.1396 28.5689C55.0489 26.8197 54.5846 25.1102 53.7779 23.5555C59.0328 26.4267 66.5232 31.8708 69.0722 38.6329C70.6849 42.9111 70.194 47.1761 67.5714 51.6721C66.5399 53.4406 65.4815 55.1785 64.4583 56.8594C56.9682 69.1606 49.8631 80.8281 57.0115 98.9596H49.1256Z"
        fill="#EA593D"
      />
      <path
        d="M99.283 75.7668C99.2327 75.5672 99.1802 75.3668 99.1256 75.1656C96.9462 67.1751 84.8826 56.9009 83.5143 55.7558L80.7666 53.4563L78.9647 56.5534C74.8587 63.613 71.335 70.0519 69.7989 77.3569C68.0475 85.6875 69.2264 94.1543 73.4042 103.241L74.2122 105H76.2655C79.882 105.01 83.4527 104.19 86.7023 102.603C89.9519 101.016 92.7938 98.7039 95.009 95.8451C97.2106 93.0488 98.7409 89.7843 99.4819 86.3032C100.223 82.8221 100.155 79.2174 99.283 75.7668ZM90.2454 92.1289C88.7814 94.0189 86.948 95.591 84.8569 96.7496C82.7657 97.9081 80.4606 98.6287 78.0819 98.8675C72.1284 84.5408 75.6411 74.7754 82.3535 62.7932C86.816 66.9448 92.2547 72.9276 93.2981 76.754C93.342 76.9154 93.3841 77.0761 93.4244 77.236C94.0697 79.7962 94.1187 82.4703 93.5675 85.0523C93.0164 87.6344 91.8798 90.0554 90.2454 92.1289Z"
        fill="#EA593D"
      />
    </Icon>
  );
}
