import { Icon } from '@chakra-ui/react';

export function CloseIcon(props) {
  return (
    <Icon viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M3.19531 3.19971L20.7468 20.7512"
        stroke="#F0F0F0"
        strokeWidth="2.4"
        strokeLinecap="round"
      />
      <path
        d="M20.7468 3.19971L3.19531 20.7512"
        stroke="#F0F0F0"
        strokeWidth="2.4"
        strokeLinecap="round"
      />
    </Icon>
  );
}
